import styled from "styled-components";

import { CounterStyledProps as Props } from "./Counter.types";

const CounterStyled = styled.div<Props>`
  display: inline-block;
  background-color: ${props =>
    props.disabled ? "var(--palette-primary-light)" : "var(--palette-primary)"};
  border-radius: 0.4rem;

  .Counter {
    &__quantity {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${props =>
        props.disabled
          ? "var(--palette-primary-light)"
          : "var(--palette-primary)"};
      color: ${props =>
        props.disabled
          ? "var(--palette-primary-dark)"
          : "var(--palette-white)"};
      border-radius: 0.4rem;
      padding: 0 0.6rem;
      font-weight: 600;
      margin: 0 0.2rem;
    }

    &__button {
      background-color: var(--palette-primary);
      height: 2.4rem;
      border: 0;
      width: 2.4rem;
      padding: 0;
      border-radius: 0.4rem;
      box-shadow: 0 0 0 0;

      &:disabled {
        background-color: var(--palette-primary-light);
        border: 0;
        box-shadow: var(--palette-primary-light) 0 0 0 0.02rem;

        path {
          fill: var(--palette-primary);
        }
      }
    }
  }
`;

export default CounterStyled;
