import styled from "styled-components";

import { ChooseStoreStyledProps as Props } from "./ChooseStore.types";

const ChooseStoreStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  flex: 1;

  .ChooseStore {
    &__options {
      background-color: var(--palette-white);
      padding: 1.6rem 2.4rem;
    }

    &__current-position {
      .InfoCard {
        &__content {
          display: flex;
          flex-direction: column-reverse;
          cursor: pointer;

          &__value {
            font-weight: 600;
          }
        }
      }
    }

    &__map-search {
      margin-top: 1.6rem;

      .InfoCard {
        &__icon {
        }

        &__content {
          cursor: pointer;

          &__value {
            font-weight: 600;
          }
        }
      }
    }

    &__reverse-geocode-error {
      color: var(--palette-red-s60-l60);
      font-size: 1.2rem;
      padding-top: 0.8rem;
    }
  }
`;

export default ChooseStoreStyled;
