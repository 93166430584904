import { createApp } from "artisn/init";
import { Providers } from "artisn/analytics";
import { initAuth, getServices } from "artisn/auth";

import CONSTANTS from "config/constants";

const { ARTISN, GENERAL, API } = CONSTANTS;
const { PLATFORM } = GENERAL;
const { ACCOUNT_ID } = ARTISN;
const { API_URL } = API;

export const getENVs = (() => {
  const env = process.env.NEXT_PUBLIC_ENV ?? "develop";

  return {
    develop: {
      apiKey: "AIzaSyBswxS0ztA72VgTWrN3birvdJekMWc0RvE",
      authDomain: "atn-prod.firebaseapp.com",
      projectId: "atn-prod",
      databaseURL: "https://simple-commerce-dev-default-rtdb.firebaseio.com",
      storageBucket: "atn-prod.appspot.com",
      messagingSenderId: "505684262695",
      appId: "1:505684262695:web:901d0346c20aebf2223302",
      measurementId: "G-TG2Q1JPL3E",
      mapsApiKey: "AIzaSyD-o9htbV86JDu0mMka5nhz3vuYbBR_oAg",
      facebookAppId: "695780938556201"
    },
    production: {
      apiKey: "",
      authDomain: "",
      projectId: "",
      storageBucket: "",
      messagingSenderId: "",
      appId: "",
      measurementId: "",
      mapsApiKey: "",
      facebookAppId: ""
    }
  }[env];
})();

export const artisn = createApp(
  {
    projectId: getENVs?.projectId ?? "",
    apiKey: getENVs?.apiKey ?? "",
    authDomain: `${getENVs?.projectId}.firebaseapp.com`,
    accountId: ACCOUNT_ID,
    platform: PLATFORM
  },
  API_URL
);

export const auth = () => initAuth(artisn);
export const Services = getServices(artisn);
export const firestore = artisn?.__internals__!.firestore();

export const facebookProvider = new Providers.FacebookPixel({
  name: "facebook",
  trackingId: getENVs?.facebookAppId ?? ""
});

export const googleProvider = new Providers.GoogleAnalytics({
  name: "google",
  trackingId: getENVs?.measurementId ?? ""
});
