import styled from "styled-components";

import { OutOfServiceModalStyledProps as Props } from "./OutOfServiceModal.types";

const OutOfServiceModalStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25.8rem;

  .OutOfServiceModal {
    &__title {
      font-size: 2.4rem;
      line-height: 2.8rem;
      text-align: center;
      padding-bottom: 1.2rem;
    }

    &__description {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 600;
      padding-bottom: 2.4rem;
      text-align: center;
      color: var(--palette-gray-s0-l35);
    }

    &__button {
      padding: 1.6rem 2rem;
      font-size: 1.6rem;
      font-weight: 600;
    }

    &__svg {
      background-color: var(--palette-h0s0l98a1);
      width: 13.2rem;
      height: 13.2rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;
    }
  }
`;

export default OutOfServiceModalStyled;
