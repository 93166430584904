import React from "react";
import { StaticMap } from "artisn-ui-react";

import Styles from "./StoreDetailsPickup.styles";
import { StoreDetailsPickupProps as Props } from "./StoreDetailsPickup.types";
import StorePreview from "components/global/StorePreview/StorePreview";
import Schedule from "components/global/Schedule/Schedule";
import useCatalogues from "contexts/catalogues/catalogues.hooks";
import { getENVs } from "config/artisn.config";
import { getMapSize, isTouchScreenDevice } from "utils/common.utils";
import useWindowSize from "hooks/useWindowSize";
import Button from "components/global/Button/Button";
import { chooseMapProvider } from "./StoreDetailsPickup.helpers";
import { getMaxWidth } from "utils/styling.utils";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const StoreDetailsPickup: React.FC<Props> = props => {
  const { store } = props;
  const { latitude, longitude, address } = store ?? {};
  const { selectedCatalogueId } = useCatalogues();
  const { width, height } = useWindowSize();
  const { height: mapHeight } = getMapSize(width, height);

  const isMobile = width <= tablet;
  const maxWidth = +getMaxWidth().replace("px", "");
  const padding = isMobile ? 32 : 64;
  const mapWidth =
    typeof isTouchScreenDevice() === "boolean"
      ? maxWidth - padding
      : 600 - padding;

  const clickHandler = () => {
    window.open(chooseMapProvider("google", latitude, longitude), "__blank");
  };

  return (
    <Styles className="StoreDetailsPickup">
      {store ? (
        <StorePreview
          className="StoreDetailsPickup__store-preview"
          store={store}
        />
      ) : null}
      <Schedule
        className="StoreDetailsPickup__schedule"
        schedule={store?.schedules}
        catalogueId={selectedCatalogueId}
      />
      <div className="StoreDetailsPickup__address-info">
        <p className="StoreDetailsPickup__address-info__title">Dirección</p>
        <p className="StoreDetailsPickup__address-info__description">
          {address}
        </p>
        {latitude && longitude ? (
          <StaticMap
            className="StoreDetailsPickup__static-map"
            googleMapsKey={`${getENVs?.mapsApiKey}`}
            width={mapWidth}
            height={mapHeight}
            markers={[
              {
                coordinates: { lat: latitude, lng: longitude },
                icon: "https://res.cloudinary.com/dqkt1uhsg/image/upload/v1631133722/Simple%20Commerce/Map/store-marker_lwsxlk.png"
              }
            ]}
          />
        ) : null}
      </div>
      <div className="StoreDetailsPickup__button-container">
        <Button
          className="StoreDetailsPickup__open-in-maps-button"
          type="BORDER"
          onClick={clickHandler}
        >
          Ver en Google maps
        </Button>
      </div>
    </Styles>
  );
};

StoreDetailsPickup.defaultProps = {};

export default StoreDetailsPickup;
