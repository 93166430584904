import styled from "styled-components";

import { ChooseStoreModalStyledProps as Props } from "./ChooseStoreModal.types";
import { ModalHeaderStyledProps } from "./ChooseStoreModal.types";
import CONSTANTS from "../../../config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const ChooseStoreModalStyled = styled.div<Props>`
  height: 100%;
  display: flex;

  @media (max-width: ${mobile - 1}px) {
    display: block;
  }

  .ChooseStoreModal {
    &__go-back {
    }
  }
`;

export const ModalHeaderStyled = styled.div<ModalHeaderStyledProps>`
  display: flex;
  align-items: center;
  padding: ${props => (props.step === 1 ? "1.6rem 2.4rem" : "0")};
  font-weight: 600;
  font-size: 1.6rem;
`;

export default ChooseStoreModalStyled;
