import { lighten, darken } from "polished";

const primary = "hsla(193, 46%, 28%, 1)";

const palette = {
  empty: "", // Fixes a bug with styled components that appends a coma
  "primary-dark": darken(0.12, primary),
  "primary-light": lighten(0.47, primary),
  // This variable is for capacitor use only, it represents the primary color.
  "primary-hex": "hsla(255,85%,45%,1)",
  "purple-s85-l45": primary,
  h255s70l95a1: "hsla(255, 70%, 95%, 1)",
  h15s15l95: "hsla(15, 15%, 95%, 1)",
  primary: "var(--palette-purple-s85-l45)",
  h90s100l40a1: "hsla(90, 100%, 40%, 1)",
  h145s65l45a1: "hsla(145, 65%, 45%, 1)",
  h0s60l85a1: "hsla(0, 60%, 85%, 1)",
  h0s60l60a1: "hsla(0, 60%, 60%, 1)",
  h5s100l95a1: "hsla(5, 100%, 95%,1)",
  h215s90l50a1: "hsla(215, 90%, 50%, 1)",
  h50s100l55a1: "hsla(50, 100%, 55%, 1)",
  h193s46l28a1: "hsla(195, 45%, 30%, 1)",
  h193s46l88a1: "hsla(193, 46%, 88%, 1)",
  h29s75l57a1: "hsla(29, 75%, 57%, 1)",
  h29s75l57a02: "hsla(29, 75%, 57%, 0.2)",
  h29s75l57a01: "hsla(29, 75%, 57%, 0.1)",
  h200s97l45a1: "hsla(200, 97%, 45%, 1)",
  h355s90l55a1: "hsla(355,90%,55%,1)",
  h145s63l42a023: "hsla(145, 63%, 42%, 0.23)",
  h265s10l15a1: "hsla(265, 10%, 15%, 1)",
  h270s10l15a1: "hsla(270, 10%, 15%, 1)",
  h195s45l90a1: "hsla(195, 45%, 90%, 1)",
  h195s35l90a1: "hsla(195, 35%, 90%, 1)",
  h90s100l95a1: "hsla(90, 100%, 95%,1)",
  h95s70l75a1: "hsla(95, 70%, 75%, 1)",
  h5s100l85a1: "hsla(5, 100%, 85%, 1)",
  h200s95l45a1: "hsla(200, 95%, 45%, 1)",
  // This variable is for capacitor use only, it represents the black color.
  "black-hex": "#000000",
  black: "hsla(0, 0%, 0%, 1)",
  h0s0l0a0: "hsla(0, 0%, 0%, 0)",
  h0s0l10a1: "hsla(0, 0%, 10%, 1)",
  h0s0l20a040: "hsla(0, 0%, 20%, 0.04)",
  h0s0l20a080: "hsla(0, 0%, 20%, 0.08)",
  h0s0l20a005: "hsla(0, 0%, 20%, 0.05)",
  h0s0l20a01: "hsla(0, 0%, 20%, 0.1)",
  h0s0l20a015: "hsla(0, 0%, 20%, 0.15)",
  h0s0l20a020: "hsla(0, 0%, 20%, 0.20)",
  h0s0l20a070: "hsla(0, 0%, 20%, 0.70)",
  h0s0l20a1: "hsla(0, 0%, 20%, 1)",
  h0s0l30a1: "hsla(0, 0%, 30%, 1)",
  h0s0l35a1: "hsla(0, 0%, 35%, 1)",
  h0s0l40a05: "hsla(0, 0%, 40%, 0.5)",
  h0s0l40a1: "hsla(0, 0%, 40%, 1)",
  h0s0l45a1: "hsla(0, 0%, 45%, 1)",
  h0s0l50a1: "hsla(0, 0%, 50%, 1)",
  h0s0l51a05: "hsla(0, 0%, 50%, 0.5)",
  h0s0l65a1: "hsla(0, 0%, 65%, 1)",
  h0s0l70a05: "hsla(0, 0%, 70%, 0.5)",
  h0s0l70a1: "hsla(0, 0%, 70%, 1)",
  h0s0l75a1: "hsla(0, 0%, 75%, 1)",
  h0s0l80a1: "hsla(0, 0%, 80%, 1)",
  h0s0l85a1: "hsla(0, 0%, 85%, 1)",
  h0s0l90a1: "hsla(0, 0%, 90%, 1)",
  h0s0l95a1: "hsla(0, 0%, 95%, 1)",
  h0s0l98a1: "hsla(0, 0%, 98%, 1)",
  h0s0l100a1: "hsla(0, 0%, 100%, 1)",
  h270s8l15a1: "hsla(270, 8%, 15%, 1)",
  h15s15l95a1: "hsla(15, 15%, 95%, 1)",
  h0s0l0a025: "hsla(0, 0%, 0%, 0.25)",
  // This variable is for capacitor use only, it represents the white color.
  "white-hex": "#ffffff",
  white: "hsla(0, 0%, 100%, 1)",
  text: {
    primary: "var(--palette-primary)",
    white: "var(--palette-white)"
  }
};

const fonts = {
  primary: "Poppins"
};

const sizes = {
  page: {
    minWidth:
      "calc(100vw - (100vw - 100%) - env(safe-area-inset-left) - env(safe-area-inset-right))",
    maxWidth:
      "calc(100vw - (100vw - 100%) - env(safe-area-inset-left) - env(safe-area-inset-right))",
    minHeight:
      "calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))",
    maxHeight:
      "calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))",
    columns:
      "minmax(1.6rem, 1fr) [content-start] minmax(0, 120rem) [content-end] minmax(1.6rem, 1fr)"
  },
  navbar: {
    height: "calc(7.6rem + env(safe-area-inset-top))",
    "height-mobile": "calc(10.8rem + env(safe-area-inset-top))",
    "height-mobile-without-search": "calc(5.8rem + env(safe-area-inset-top))"
  }
};

const variables = { palette, fonts, sizes };

export default variables;
