// Company services mock data
import { Company } from "types/atn/company.types";
import { CompanyForm } from "types/atn/company.types";
import { UserCompany } from "types/atn/company.types";
import { AttributeLabel } from "./company.service.types";

export const mockCompanies: Company[] = [];
export const mockCompanyForms: CompanyForm[] = [];
export const userCompanies: UserCompany[] = [];
export const mockAttributeLabel: AttributeLabel[] = [];

export const mockCompany: Company = {
  id: "",
  city: "",
  country: "",
  email: "",
  name: "",
  opened: true,
  createdAt: "",
  updatedAt: "",
  status: ""
};

export const mockCompanyForm: CompanyForm = {
  id: "",
  createdAt: "",
  json: {},
  status: "APPROVED",
  updatedAt: ""
};

export const mockCompanyUser: UserCompany = {
  firstName: "",
  lastName: "",
  company: mockCompany
};

export const mockAtribbuteLabel: AttributeLabel = {
  id: "",
  label: "",
  masterDataFieldName: {
    name: ""
  }
};
