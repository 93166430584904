// Initialize Artisn
import useSetupShoppingCart from "./useSetupShoppingCart";
import useSetupAnalytics from "./useSetupAnalytics";

const useSetupArtisn = () => {
  useSetupShoppingCart();
  useSetupAnalytics();
};

export default useSetupArtisn;
