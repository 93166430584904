import React, { useMemo, useState } from "react";
import { validateShoppingCart } from "artisn/shopping-cart";
import { getShoppingCartTotal } from "artisn/shopping-cart";
import { getShoppingCartProducts } from "artisn/shopping-cart";
import { useRouter } from "next/router";

import Styles from "./CartPayButton.styles";
import { CartPayButtonProps as Props } from "./CartPayButton.types";
import Button from "components/global/Button/Button";
import useGeo from "contexts/geo/geo.hooks";
import useAuth from "contexts/auth/auth.context.hooks";
import { defaultFunction, shouldMock } from "utils/common.utils";
import CONSTANTS from "config/constants";
import ShoppingCartNotifications from "components/global/notifications/ShoppingCartNotifications/ShoppingCartNotifications";
import { usePostTalkShop } from "services/talkShop/talkShop/talkShop.service.hooks";
import useTalkShop from "contexts/talkShop/talkShop/talkShop.context.hooks";
import useCountries from "contexts/countries/countries.hooks";
import useShoppingCart from "contexts/shoppingCart/shoppingCart.context.hooks";
import { getCartMessage } from "./CartPayButton.helpers";
import { dismissErrorNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";
import useStores from "contexts/stores/stores.context.hooks";

const { ARTISN } = CONSTANTS;
const { SHOPPING_CART_DEFAULT_NAME } = ARTISN;
const { PHONE_NUMBER, RETURN_TO_APP_URL } = CONSTANTS.INTEGRATIONS.WHATSAPP;
const { WITH_PURCHASE, WITH_DELIVERY } = CONSTANTS.FEATURE_FLAGS;

const CartPayButton: React.FC<Props> = props => {
  const { empty, shoppingCart, setDisabled = defaultFunction } = props;
  const { setErrors = defaultFunction, className, talkShop } = props;
  const { selectedCoordinates } = useGeo();
  const { uid, isAnonymous } = useAuth();
  const [showStoreNotification, setShowStoreNotification] = useState(false);
  const [showNoCoverage, setShowNoCoverage] = useState(false);
  const [showMapCoordinates, setShowMapCoordinates] = useState(false);
  const router = useRouter();
  const { mutate, isLoading } = usePostTalkShop();
  const cartProducts = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartProducts(shoppingCart);
  }, [shoppingCart]);
  const { talkShopIdentifier } = useTalkShop();
  const { selectedCountry } = useCountries();
  const { showModifiers } = useShoppingCart();
  const { selectedStore } = useStores();
  const shoppingCartTotal = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartTotal(shoppingCart);
  }, [shoppingCart]);
  const { minOrderAmount = 0, maxOrderAmount = 0 } = selectedStore ?? {};
  const { total = 0 } = shoppingCartTotal ?? {};

  const payButtonHandler = async () => {
    if (minOrderAmount > 0 && minOrderAmount > total) {
      dismissErrorNotification();
      createErrorNotification(
        `Agrega al menos $${minOrderAmount} para realizar tu pedido`,
        "Tu pedido no cumple con el monto mínimo"
      );
      return;
    }

    if (maxOrderAmount > 0 && maxOrderAmount < total) {
      dismissErrorNotification();
      createErrorNotification(
        `Agrega artículos hasta un valor de $${maxOrderAmount} para poder hacer tu pedido`,
        "Tu pedido excede el monto máximo"
      );
      return;
    }

    if (!selectedCoordinates) {
      setShowMapCoordinates(true);
      return;
    }
    if (!shoppingCart || !uid) return;
    if (!Object.keys(shoppingCart.stores).length) return;
    const { lat, lng } = selectedCoordinates;
    const validate = !shouldMock ? validateShoppingCart : () => undefined;

    const errors = await validate({
      latitude: lat,
      longitude: lng,
      shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
      anonymous: isAnonymous
    });
    const { products, stores } = errors ?? {};
    stores?.forEach(store => {
      const { type } = store;
      if (type === "IS_OPEN" && stores.length === 1) {
        setShowStoreNotification(true);
      }
      if (type === "OUT_OF_COVERAGE" && WITH_DELIVERY) {
        setShowNoCoverage(true);
      }
    });
    const validationErrors = stores?.filter(store => {
      if (!WITH_DELIVERY) {
        return !(store.type === "OUT_OF_COVERAGE");
      }
      return store;
    });
    const hasErrors = !!products?.length || !!validationErrors?.length;
    if (hasErrors && !shouldMock) {
      setDisabled(true);
      setErrors(errors);
      return;
    }

    const talkShopData = {
      to: `whatsapp:+${talkShopIdentifier.trim()}`,
      from: `whatsapp:+${PHONE_NUMBER}`,
      body: `${getCartMessage(
        cartProducts,
        showModifiers,
        shoppingCart,
        selectedCountry
      )}`
    };

    if (talkShop) {
      mutate(talkShopData, {
        onSuccess: () => {
          router.push(RETURN_TO_APP_URL);
        }
      });
      return;
    }

    router.push("/checkout?redirect=false");
  };

  return (
    <Styles className={`CartPayButton ${className}`} talkShop={!!talkShop}>
      <Button
        onClick={payButtonHandler}
        disabled={empty || isLoading || !WITH_PURCHASE}
        className="Cart__summary__button"
      >
        Ir a pagar
      </Button>
      <ShoppingCartNotifications
        showMapCoordinates={showMapCoordinates}
        showNoCoverage={showNoCoverage}
        showClosedStore={showStoreNotification}
        setShowMapCoordinates={setShowMapCoordinates}
        setShowNoCoverage={setShowNoCoverage}
        setClosedStore={setShowStoreNotification}
      />
    </Styles>
  );
};

CartPayButton.defaultProps = {
  className: ""
};

export default CartPayButton;
