import styled from "styled-components";

import { InfoActionModalStyledProps as Props } from "./InfoActionModal.types";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const InfoActionModalStyled = styled.div<Props>`
  padding: 2.4rem;
  max-width: 40rem;
  /* height: 40.2rem; */
  min-width: 24rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${mobile}px) {
    min-height: 100vh;
    width: 100vw;
    padding-top: 2.4rem;
  }

  .InfoActionModal {
    &__container_icon {
      padding: 1.6rem;
      border-radius: 5rem;
      line-height: 0;
      background-color: var(--palette-h0s0l95a1);
    }

    &__title {
      padding-top: ${props => (props.hasIcon ? "3.6" : "1.2")}rem;
      font-size: 2.4rem;
      font-weight: 400;
      text-align: center;
    }

    &__description {
      line-height: 2.4rem;
      padding-top: 1.2rem;
      font-size: 1.6rem;
      font-weight: 500;
      text-align: center;
      color: var(--palette-h0s0l50a1);
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-top: 1rem;

      @media (max-width: ${mobile}px) {
        flex-direction: column;
      }
    }

    &__button {
      flex: 1;
      line-height: 2.4rem;
      font-weight: 600;
      padding: 2.4rem;
      font-size: 1.8rem;
      border-radius: 1.2rem;
      border-color: var(--palette-h0s0l20a1);
      background-color: var(--palette-h0s0l20a1);
      color: var(--palette-white);

      &:not(:first-child) {
        margin-top: 1rem;

        @media (max-width: ${mobile}px) {
          margin-left: 0;
          margin-top: 0.8rem;
        }
      }
    }

    &__cancel-button {
      border-color: var(--palette-h0s0l95a1);
      background-color: var(--palette-h0s0l95a1);
      color: var(--palette-black-s0-l10);
    }
  }
`;

export default InfoActionModalStyled;
