import styled from "styled-components";

import { ShoppingCartProductStyledProps as Props } from "./ShoppingCartProduct.types";
import { ShoppingCartProductPlaceholderProps as PlaceholderProps } from "./ShoppingCartProduct.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const ShoppingCartProductStyled = styled.div<Props>`
  display: grid;
  gap: 0.8rem;
  height: auto;
  padding: 0.72rem 1.2rem 0.72rem 0.8rem;
  border-bottom: 1px solid var(--palette-gray-s0-l95);
  position: relative;

  .ShoppingCartProduct {
    &__modifyProduct {
      display: flex;
      align-items: center;

      @media (max-width: ${tablet}px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 1.2rem;
      }
    }

    &__removeButton {
      width: 3.2rem;
      height: 4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: var(--palette-h0s0l98a1);
      border: none;
      border-radius: 0.4rem;
      cursor: pointer;
    }

    &__error {
      font-size: 1.2rem;
      color: var(--palette-red-s60-l60);
      line-height: 1.6rem;
      display: flex;
      align-items: center;
      padding-left: 1.2rem;

      @media (max-width: ${tablet}px) {
        grid-row: 2/3;
        grid-column: 1/3;
        padding-left: 0;
      }

      svg {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.6rem;

        path {
          fill: var(--palette-red-s60-l60);
        }
      }
    }

    &__counter {
      height: 3.2rem;
      max-width: 9.4rem;
      margin-left: auto;

      .Counter {
        height: inherit;

        &__quantity {
          height: 2.8rem;
          width: 2.8rem;
        }

        &__button {
          height: inherit;
          width: 3.2rem;
        }

        &__quantity,
        &__button:first-of-type {
          display: ${props => (!props.count ? "none" : "flex")};
        }
      }
    }
  }
`;

export const ShoppingCartProductPlaceholderStyled = styled.div<PlaceholderProps>`
  height: 16.6rem;
  display: flex;
  padding: 0.8rem;

  @media (max-width: ${tablet - 1}px) {
    height: 11.4rem;
  }

  .ShoppingCartProductPlaceholder {
    &__details {
      padding: 1.2rem 0.8rem;
    }

    &__image {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 10rem;
      overflow: hidden;
      position: relative;
      width: 10rem;
    }

    &__price {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 1.6rem;
      overflow: hidden;
      position: relative;
      width: 4.2rem;
    }

    &__name {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 1.6rem;
      overflow: hidden;
      position: relative;
      width: 18.4rem;

      @media (max-width: ${tablet - 1}px) {
        width: 16rem;
      }
    }

    &__description {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 1.6rem;
      overflow: hidden;
      margin-top: 0.8rem;
      margin-bottom: 1.2rem;
      position: relative;
      width: 7.2rem;
    }

    &__shine {
      animation: shine 2.5s ease-in-out infinite;
      background-color: var(--palette-h0s0l98a1);
      height: 300%;
      position: absolute;
      width: 25%;
      opacity: 0.4;
    }
  }
`;

export default ShoppingCartProductStyled;
