// PaymentMethods services
import { Catalogue, PaymentMethod, Store, Vendor } from "artisn/types";

import { Card } from "types/payment.types";
import axiosDefault from "utils/axios.utils";
import { auth } from "config/artisn.config";
import { PaymentProviderId } from "contexts/payments/payments.context.types";
import { shouldMock } from "utils/common.utils";
import { CommonResponse } from "types/common.types";
import { PayPhoneCredentials } from "./payments.service.types";
import { StoreCredentials } from "./payments.service.types";
import CONSTANTS from "config/constants";

const { WITH_PAYPHONE_EXPRESS_CHECKOUT } = CONSTANTS.FEATURE_FLAGS;

/**
 * Fetches the available payment methods for the commerce.
 *
 * @returns {PaymentMethod[]} The list of available payment methods
 */
export const fetchPaymentMethods = async (
  catalogueId: Catalogue["catalogueId"],
  vendorId: Vendor["id"]
): Promise<PaymentMethod[]> => {
  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.get(
        `/api/payment-method/vendor/${vendorId}/channel/${catalogueId}`
      );
      return data.data.reverse();
    } else {
      const { mockPaymentMethods } = await import("./payments.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockPaymentMethods(2));
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Fetches the available cards to the user in the Payment Method in a store.
 *
 * @param {number} paymentMethodId Payment Method id to get cards
 * @param {number} storeId Store id to get cards
 * @returns {Card[]} The list of available cards
 */
export const fetchCards = async (
  paymentProviderId: PaymentProviderId,
  storeId: Store["storeId"],
  vendorId: Vendor["id"]
): Promise<Card[]> => {
  const url = `api/payment-inscriptions/list/method/${paymentProviderId}`;
  try {
    const token = await auth().currentUser?.getIdToken();
    if (!shouldMock) {
      const params = {
        vendorId,
        storeId
      };
      const headers = {
        Authorization: `Bearer ${token}`
      };

      const { data } = await axiosDefault.get(url, { headers, params });

      return data.data;
    } else {
      const isPayPhone = paymentProviderId === PaymentProviderId.PAY_PHONE_ID;
      let mockCards: Card[];
      if (isPayPhone) {
        const { mockPayPhoneCards } = await import("./payments.service.mock");
        mockCards = mockPayPhoneCards;
      } else {
        const { mockKushkiCards } = await import("./payments.service.mock");
        mockCards = mockKushkiCards;
      }
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockCards);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Fetches store credentials for use the payment method provider.
 *
 * @param {number} paymentProviderId Payment method provider id to get store credential
 * @param {number} storeId Store id to get store credential
 * @returns {string} Store credential for payment method provider
 */
export const fetchStorePaymentCredentials = async (
  paymentProviderId: number,
  storeId: number
): Promise<string | PayPhoneCredentials> => {
  const url = `api/payment-method/${paymentProviderId}/store/${storeId}/credentials`;

  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.get<CommonResponse<StoreCredentials>>(
        url
      );
      const { data: storeCredentials } = data;
      const { key, additional_info, secret } = storeCredentials ?? {};
      const { auth_token } = additional_info ?? {};
      const isKushki = paymentProviderId === PaymentProviderId.KUSHKI_ID;
      const isPayPhone = paymentProviderId === PaymentProviderId.PAY_PHONE_ID;
      if (isKushki && key) return key;
      if (isPayPhone && key && auth_token && WITH_PAYPHONE_EXPRESS_CHECKOUT) {
        return { appId: key, token: auth_token };
      }
      if (isPayPhone && secret && !WITH_PAYPHONE_EXPRESS_CHECKOUT) {
        return secret;
      }
      throw new Error("No se ha podido obtener las credenciales de la tienda");
    } else {
      const { mockStoreCredential } = await import("./payments.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockStoreCredential);
        }, 1000);
      });
    }
  } catch (error) {
    if (error.isAxiosError) {
      throw new Error("No se ha podido obtener las credenciales de la tienda");
    } else {
      throw new Error(error.message);
    }
  }
};
