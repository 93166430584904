// Payment Methods service hooks
import { useQuery } from "react-query";
import { Vendor } from "artisn/types";

import useAuth from "contexts/auth/auth.context.hooks";
import useStores from "contexts/stores/stores.context.hooks";
import usePayments from "contexts/payments/payments.context.hooks";
import useVendors from "contexts/vendors/vendors.hooks";
import useCatalogues from "contexts/catalogues/catalogues.hooks";
import { fetchCards, fetchPaymentMethods } from "./payments.service";
import { fetchStorePaymentCredentials } from "./payments.service";
import { PaymentProviderId } from "contexts/payments/payments.context.types";

export const useFetchPaymentMethods = (vendorId?: Vendor["id"]) => {
  const { selectedVendorId } = useVendors();
  const { selectedCatalogueId } = useCatalogues();
  const chosenVendorId = vendorId ?? selectedVendorId;

  return useQuery(
    ["paymentMethods", { selectedCatalogueId, chosenVendorId }],
    () => fetchPaymentMethods(selectedCatalogueId, chosenVendorId),
    {
      staleTime: 60 * 1000 * 60
    }
  );
};

/**
 * Hook to get user's cards
 *
 * @returns {UseQueryResult<string>} Returns a use query result with user's
 * cards
 */
export const useFetchCards = (vendorId?: Vendor["id"]) => {
  const { selectedStore } = useStores();
  const { selectedPaymentProviderId } = usePayments();
  const { selectedVendorId } = useVendors();
  const chosenVendorId = vendorId ?? selectedVendorId;
  const { uid } = useAuth();
  const { storeId } = selectedStore ?? {};
  const isKushki = selectedPaymentProviderId === PaymentProviderId.KUSHKI_ID;

  return useQuery(
    [uid, "cards", selectedPaymentProviderId, { storeId, chosenVendorId }],
    () => fetchCards(selectedPaymentProviderId!, storeId!, chosenVendorId),
    {
      enabled: !!storeId && !!selectedPaymentProviderId && isKushki && !!uid,
      staleTime: 60 * 1000 * 60
    }
  );
};

/**
 * Hook to get store's credentials
 *
 * @returns {UseQueryResult<string>} Returns a use query result with store's
 * credentials
 */
export const useFetchStorePaymentCredentials = () => {
  const { selectedStore } = useStores();
  const { selectedPaymentProviderId } = usePayments();
  const { storeId } = selectedStore ?? {};
  const isKushki = selectedPaymentProviderId === PaymentProviderId.KUSHKI_ID;
  const isPayPhone =
    selectedPaymentProviderId === PaymentProviderId.PAY_PHONE_ID;

  return useQuery(
    ["paymentMethod", "credentials", selectedPaymentProviderId, storeId],
    () => fetchStorePaymentCredentials(selectedPaymentProviderId!, storeId!),
    {
      enabled:
        !!selectedPaymentProviderId && (isKushki || isPayPhone) && !!storeId
    }
  );
};
