// TalkShop services
import axios from "axios";
import { mockTalkShop } from "./talkShop.service.mock";
import { TalkShop } from "./talkShop.service.types";
import { TalkShopPayload } from "./talkShop.service.types";
import { shouldMock } from "utils/common.utils";

const baseUrl =
  "https://api.twilio.com/2010-04-01/Accounts/AC80941ab8df19a96a0c4e24366d1d340c/Messages.json";

/**
 * Send a new message
 *
 * @param {TalkShopPayload} message body
 * @returns {TalkShop} The created message
 */
export const postTalkShop = async (
  talkShopData: TalkShopPayload
): Promise<TalkShop> => {
  const { to, from, body } = talkShopData;
  const urlencoded = new URLSearchParams();
  urlencoded.append("To", to);
  urlencoded.append("From", from);
  urlencoded.append("Body", body);
  try {
    if (!shouldMock) {
      const { data } = await axios.post(baseUrl, urlencoded, {
        auth: {
          username: process.env.NEXT_PUBLIC_TWILIO_USERNAME ?? "",
          password: process.env.NEXT_PUBLIC_TWILIO_PASSWORD ?? ""
        }
      });

      return data;
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockTalkShop);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};
