import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Store } from "artisn/types";

import Map from "components/global/Map/Map";
import useGeo from "contexts/geo/geo.hooks";
import Styles from "./ChooseStoreInMap.styles";
import Button from "components/global/Button/Button";
import useStores from "contexts/stores/stores.context.hooks";
import useRequestNavigatorGeo from "hooks/useRequestNavigatorGeo";
import { useFetchStores } from "services/stores/stores.service.hooks";
import { ChooseStoreInMapProps as Props } from "./ChooseStoreInMap.types";
import useCountries from "contexts/countries/countries.hooks";
import SearchAddress from "components/global/SearchAddress/SearchAddress";
import { Google } from "types/geo.types";

const ChooseStoreInMap: React.FC<Props> = props => {
  useRequestNavigatorGeo();
  const { mapStore, setMapStore, setStep, onClose, setPreviousStep } = props;
  const [selectedPlaceInMap, setSelectedPlaceInMap] =
    useState<Google.Coordinates>();
  const { selectedCoordinates } = useGeo();
  const { data: stores } = useFetchStores();
  const { setSelectedStore } = useStores();
  const { lat, lng } = useCountries().selectedCountry;
  const defaultCoords = useMemo(() => ({ lat, lng }), [lat, lng]);
  const { address } = mapStore ?? {};

  const inputChangeHandler = useCallback((place: Google.Geocode) => {
    const { geometry } = place;
    const { location } = geometry;
    setSelectedPlaceInMap(location);
  }, []);

  const clickHandler = () => {
    setSelectedStore(mapStore);
    setPreviousStep(2);
    setStep(1);
    onClose?.();
  };

  const storeClickHandler = (store: Store) => {
    setMapStore(store);
  };

  const storeInfoClickHandler = (store: Store) => {
    storeClickHandler(store);
    setPreviousStep(2);
    setStep(3);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.position = "static";
  }, []);

  return (
    <Styles className="ChooseStoreInMap">
      <SearchAddress onChange={inputChangeHandler} />
      <div className="ChooseStoreInMap__map">
        <Map
          defaultZoom={17}
          center={selectedPlaceInMap ?? selectedCoordinates ?? defaultCoords}
          stores={stores}
          mapStore={mapStore}
          address={address}
          onStoreClick={storeClickHandler}
          onStoreInfoClick={storeInfoClickHandler}
          onStoreChange={setMapStore}
          showCoverage
        />
      </div>
      <div className="ChooseStoreInMap__confirm">
        <Button
          className="ChooseStoreInMap__confirm__button"
          onClick={clickHandler}
        >
          Confirmar ubicación
        </Button>
      </div>
    </Styles>
  );
};

ChooseStoreInMap.defaultProps = {};

export default ChooseStoreInMap;
