import { CompanyFormStatus } from "./company.types";

export interface Form {
  id: string;
  name: string;
  description: string;
  status?: CompanyFormStatus;
  show?: boolean;
  code: string;
}

export interface StatusForm {
  formId?: string;
  status?: CompanyFormStatus | string;
}

export interface Shareholder {
  document: string;
  documentType: string;
  memberEmail: string;
  memberNationality: string;
  memberPhone: string;
  memberPosition: string;
  memberShare: boolean;
  name: string;
}

export interface Representative {
  assigned: string;
  document: string;
}

export interface DetailForm {
  id: string;
  status: string;
}

export enum FormCodes {
  GENERAL = "clab8uafs00at08jmdf5eg7hj",
  NAME = "clab8x52y00h008jn0auw0g9i",
  SHAREHOLDERS = "clab8xqqz005208lbdntz0k4j",
  REPRESENTATIVES = "clab8yr9a000808l8h9gn7xvi",
  CAPITAL = "clab8yamf000708kz0w7zbpm2",
  POSSIBLE_REPRESENTATIVES = "clik1ixr000ck08m9d9r7bik1"
}

export type FormCodesType = keyof typeof FormCodes;
