import axios from "axios";

import CONSTANTS from "../config/constants";

const { API } = CONSTANTS;
const { GQL_API_URL, DEFAULT_REQUEST_TIMEOUT } = API;

const source = axios.CancelToken.source();

const axiosGqlDefault = axios.create({
  baseURL: GQL_API_URL,
  timeout: DEFAULT_REQUEST_TIMEOUT,
  cancelToken: source.token
});

// Report to logger middleware
const report = (error: any) => {
  return Promise.reject(error);
};

axiosGqlDefault.interceptors.response.use(
  (response: any) => {
    // Do something with response data
    return response;
  },
  (error: any) => {
    // Do something with response error
    return report(error);
  }
);

export const axiosHandler = axios.create({
  baseURL: GQL_API_URL,
  headers: {
    "Content-Type": "application/pdf"
  },
  responseType: "json"
});

export default axiosGqlDefault;
