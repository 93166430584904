import React, { createContext, useMemo, useState } from "react";
import { ContextDevTool } from "react-context-devtool";
import { CartProduct, ProductDetails } from "artisn/types";

import { ProductsProviderProps as Props } from "./products.context.types";
import { ProductsProviderValue } from "./products.context.types";

// @ts-ignore
export const ProductsContext = createContext<ProductsProviderValue>({});

const ProductsProvider: React.FC<Props> = props => {
  const [selectedProduct, setSelectedProduct] = useState<
    ProductDetails | CartProduct
  >();

  const resetProductsContext = () => {
    setSelectedProduct(undefined);
  };

  const value: ProductsProviderValue = useMemo(() => {
    return {
      selectedProduct,
      setSelectedProduct,
      resetProductsContext
    };
  }, [selectedProduct]);

  return (
    <ProductsContext.Provider value={value}>
      <ContextDevTool
        context={ProductsContext}
        id="products"
        displayName="Products"
      />
      {props.children}
    </ProductsContext.Provider>
  );
};

export default ProductsProvider;
