import styled from "styled-components";

import { SearchStoreStyledProps as Props } from "./SearchStore.types";
import { SearchStoreContentStyledProps } from "./SearchStore.types";

const SearchStoreStyled = styled.div<Props>`
  display: flex;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  background-color: var(--palette-white);
  position: relative;

  .SearchStore {
    &__search-bar {
      width: 100%;
    }

    &__cancel-button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
      font-size: 1.2rem;
      font-weight: 600;
      background-color: var(--palette-h0s0l98a1);
    }
  }

  &&& .SearchBar__input {
    padding: 1rem 1.2rem;
    border: none;
    line-height: 2.4rem;

    &:focus {
      box-shadow: none;
    }
  }
`;

export const SearchStoreContentStyled = styled.div<SearchStoreContentStyledProps>`
  position: fixed;
  width: inherit;
  max-height: calc(100vh - 15.6rem);
  overflow: auto;
  padding-bottom: 0.8rem;
  background-color: var(--palette-white);

  @media (hover: none) {
    width: var(--sizes-page-maxWidth);
  }

  .SearchStoreContent {
    &__store-info {
      .InfoCard {
        &__content {
          display: flex;
          flex-direction: column-reverse;
          cursor: pointer;
        }
      }
    }
  }
`;

export default SearchStoreStyled;
