import React, { useState, useEffect } from "react";
import { Store } from "artisn/types";

import Styles from "./ChooseStore.styles";
import useGeo from "contexts/geo/geo.hooks";
import StoreList from "../StoreList/StoreList";
import { getCurrentPosition } from "utils/geo.utils";
import InfoCard from "components/global/InfoCard/InfoCard";
import { ChooseStoreProps as Props } from "./ChooseStore.types";
import SearchStore from "components/global/SearchStore/SearchStore";
import { useFetchNearbyStores } from "services/stores/stores.service.hooks";
import { useFetchGoogleAddressFromCoords } from "services/geo/geo.service.hooks";

import GeoSVG from "../../../../public/assets/images/geo.svg";
import MapSVG from "../../../../public/assets/images/map.svg";

const ChooseStore: React.FC<Props> = props => {
  const { mapStore, onClose } = props;
  const { setPreviousStep, onSelectedStore, setStep, setMapStore } = props;
  const { selectedCoordinates, setSelectedCoordinates } = useGeo();
  const nearbyStoresResponse = useFetchNearbyStores();
  const { data: stores, isLoading, isError } = nearbyStoresResponse;
  const { refetch: refetchNearbyStores } = nearbyStoresResponse;
  const [geoCodeAddress, setGeoCodeAddress] = useState<string | undefined>();

  const { data: predictions, error } =
    useFetchGoogleAddressFromCoords(selectedCoordinates);
  const { message } = error ?? {};

  const currentPositionHandler = async () => {
    await getCurrentPosition(
      async position => {
        const { latitude, longitude } = position.coords;
        setSelectedCoordinates({ lat: latitude, lng: longitude });
        refetchNearbyStores();
        onClose?.();
      },
      error => {
        setGeoCodeAddress(error.message);
      }
    );
  };

  const changeHandler = (store: Store) => {
    setMapStore(store);
    setPreviousStep(1);
    setStep(2);
  };

  const onSelectedStoreInformation = (store: Store) => {
    setMapStore(store);
    setPreviousStep(1);
    setStep(3);
  };

  useEffect(() => {
    if (!predictions || !predictions.length) return;
    const { formatted_address } = predictions[0];
    setGeoCodeAddress(formatted_address);
  }, [predictions]);

  useEffect(() => {
    if (message) console.error(message);
  }, [message]);

  return (
    <Styles className="ChooseStore">
      <SearchStore onChange={changeHandler} />
      <div className="ChooseStore__options">
        <InfoCard
          Icon={GeoSVG}
          title={geoCodeAddress}
          className="ChooseStore__current-position"
          value="Seleccionar según mi ubicación actual"
          onContentClick={currentPositionHandler}
        />
        <InfoCard
          Icon={MapSVG}
          className="ChooseStore__map-search"
          value="Buscar en el mapa"
          onContentClick={() => setStep(2)}
        />
        {message ? (
          <p className="ChooseStore__reverse-geocode-error">{message}</p>
        ) : null}
      </div>
      <StoreList
        stores={stores}
        isLoading={isLoading}
        isError={isError}
        selectedStore={mapStore}
        onStoreClick={onSelectedStore}
        onStoreInfoClick={onSelectedStoreInformation}
      />
    </Styles>
  );
};

ChooseStore.defaultProps = {};

export default ChooseStore;
