import axios from "axios";

import CONSTANTS from "../config/constants";

const { API, GENERAL, ARTISN } = CONSTANTS;
const { API_URL, DEFAULT_REQUEST_TIMEOUT } = API;
const { PLATFORM } = GENERAL;
const { ACCOUNT_ID } = ARTISN;

const source = axios.CancelToken.source();

const axiosDefault = axios.create({
  baseURL: API_URL,
  timeout: DEFAULT_REQUEST_TIMEOUT,
  headers: {
    Platform: PLATFORM,
    account: `${ACCOUNT_ID}`
  },
  cancelToken: source.token
});

// Report to logger middleware
const report = (error: any) => {
  return Promise.reject(error);
};

axiosDefault.interceptors.response.use(
  (response: any) => {
    // Do something with response data
    return response;
  },
  (error: any) => {
    // Do something with response error
    return report(error);
  }
);

export default axiosDefault;
