// Interfaces and types from context Payments
import { Dispatch, SetStateAction } from "react";

import CONSTANTS from "config/constants";
import { Card } from "types/payment.types";

const { PAYMENT_METHODS, PAYMENT_PROVIDERS } = CONSTANTS.ARTISN;
const { CASH_ID: CASH, CARD_ID: CARD } = PAYMENT_METHODS;
const { KUSHKI_ID: KUSHKI, PAY_PHONE_ID: PAY_PHONE } = PAYMENT_PROVIDERS;
const { PLACE_TO_PAY_ID: PLACE_TO_PAY } = PAYMENT_PROVIDERS;

// Provider Props
export interface PaymentsProviderProps {
  children: React.ReactNode;
}

// Provider value
export interface PaymentsProviderValue {
  selectedCard: Card | undefined;
  setSelectedCard: Dispatch<SetStateAction<Card | undefined>>;
  resetPaymentsContext: () => void;
  selectedPaymentMethodId: PaymentMethodId;
  setSelectedPaymentMethodId: Dispatch<SetStateAction<PaymentMethodId>>;
  selectedPaymentProviderId: PaymentProviderId | undefined;
  setSelectedPaymentProviderId: Dispatch<
    SetStateAction<PaymentProviderId | undefined>
  >;
}

// Additional Types
export enum PaymentMethodId {
  CASH_ID = CASH,
  CARD_ID = CARD,
  OTHER = -1
}

export enum PaymentProviderId {
  KUSHKI_ID = KUSHKI,
  PAY_PHONE_ID = PAY_PHONE,
  PLACE_TO_PAY_ID = PLACE_TO_PAY
}
