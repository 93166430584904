import styled from "styled-components";

import { ErrorBlockStyledProps as Props } from "./ErrorBlock.types";

const ErrorBlockStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ErrorBlock {
    &__message {
      font-size: 1.6rem;
      padding: 1.2rem 0;
      font-weight: 600;
      color: var(--palette-gray-s0-l35);
      line-height: 2.4rem;
      width: 24rem;
      text-align: center;
    }

    &__icon {
      width: 13.6rem;
      height: 13.6rem;
      background-color: var(--palette-h0s0l98a1);
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 5.6rem;
        height: 5.6rem;

        path {
          fill: var(--palette-h0s0l80a1);
        }
      }
    }

    &__button {
      font-size: 1.6rem;
      padding: 1.6rem 2rem;
    }
  }
`;

export default ErrorBlockStyled;
