import React, { memo, useEffect, useState } from "react";
import Head from "next/head";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Keyboard, KeyboardResize } from "@capacitor/keyboard";

import Styles from "./Layout.styles";
import { LayoutProps as Props } from "./Layout.types";
import useListeners from "hooks/useListeners";
import useSetupArtisn from "hooks/artisn/useSetupArtisn";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";
import { getENVs, firestore } from "config/artisn.config";
import useCountries from "contexts/countries/countries.hooks";
import StoreNotification from "components/global/notifications/StoreNotification/StoreNotification";
import OutOfServiceModal from "components/global/OutOfServiceModal/OutOfServiceModal";
import UpdateAppModal from "components/global/UpdateAppModal/UpdateAppModal";

const { TITLE, GENERAL, ARTISN, APP } = CONSTANTS;
const { VERSION_CODE } = GENERAL;
const { ACCOUNT_ID } = ARTISN;
const { APPLE_STORE_URL, PLAY_STORE_URL, WEB_URL } = APP;

const Layout: React.FC<Props> = props => {
  const { children } = props;
  useSetupArtisn();
  useListeners();
  const { locale } = useCountries().selectedCountry;
  const isIOS = Capacitor.getPlatform() === "ios";
  const isAndroid = Capacitor.getPlatform() === "android";
  const [remoteVersionCode, setRemoteVersionCode] = useState("");
  const [updateApp, setUpdateApp] = useState(false);
  const isMobile = isIOS || isAndroid;

  useEffect(() => {
    if (isIOS) {
      Keyboard.setResizeMode({ mode: KeyboardResize.None });
    }

    if (isMobile) {
      StatusBar.setStyle({ style: Style.Light });
    }

    if (isAndroid) {
      StatusBar.setBackgroundColor({ color: variables.palette["white-hex"] });
    }
  }, [isAndroid, isIOS, isMobile]);

  useEffect(() => {
    (async () => {
      try {
        if (
          isMobile &&
          remoteVersionCode &&
          remoteVersionCode !== "0" &&
          remoteVersionCode !== VERSION_CODE
        ) {
          const semver = await import("semver");
          setUpdateApp(semver.default.gte(remoteVersionCode, VERSION_CODE));
          return;
        }
        setUpdateApp(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [isMobile, remoteVersionCode]);

  useEffect(() => {
    firestore
      ?.collection("appParameters")
      .doc(`${ACCOUNT_ID}`)
      .onSnapshot(doc => {
        setRemoteVersionCode(doc.data()?.versionCode);
      });
  }, []);

  return (
    <Styles className="Layout">
      <Head>
        <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
        <script
          defer
          src={`https://maps.googleapis.com/maps/api/js?key=${getENVs?.mapsApiKey}&libraries=places&language=${locale}`}
        />
        <title>{TITLE}</title>
      </Head>
      {children}
      <StoreNotification />
      {isMobile && remoteVersionCode === "0" ? (
        <OutOfServiceModal url={WEB_URL} />
      ) : null}
      {updateApp ? (
        <UpdateAppModal storeUrl={isIOS ? APPLE_STORE_URL : PLAY_STORE_URL} />
      ) : null}
    </Styles>
  );
};

Layout.defaultProps = {};

export default memo(Layout);
