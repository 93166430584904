import React from "react";
import Link from "next/link";
import { Modal } from "artisn-ui-react";

import Styles from "./UpdateAppModal.styles";
import { UpdateAppModalProps as Props } from "./UpdateAppModal.types";
import Button from "../Button/Button";
import { backdropConfig } from "./UpdateAppModal.helpers";

import CloudSVG from "../../../../public/assets/images/cloud-download.svg";

const UpdateAppModal: React.FC<Props> = props => {
  const { storeUrl } = props;

  return (
    <Modal
      backdropConfig={backdropConfig}
      className="UpdateAppModal__modal"
      opened={true}
    >
      <Styles className="UpdateAppModal">
        <div className="UpdateAppModal__svg">
          <CloudSVG />
        </div>
        <h1 className="UpdateAppModal__title">
          Hay una nueva versión de tu app
        </h1>
        <p className="UpdateAppModal__description">
          La versión de tu app caducó. Descarga la nueva versión en la tienda.
        </p>
        <Link href={storeUrl} passHref>
          <a
            className="Footer__privacy-and-terms__item"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button className="UpdateAppModal__button">Descargar ahora</Button>
          </a>
        </Link>
      </Styles>
    </Modal>
  );
};

UpdateAppModal.defaultProps = {};

export default UpdateAppModal;
