// Geo services
import axios from "axios";

import { Google } from "types/geo.types";
import { getENVs } from "config/artisn.config";
import { genAutocompleteResult } from "utils/testUtils/builders/geo.builder";
import { shouldMock } from "utils/common.utils";
import { Country } from "types/country.types";
/**
 * Fetches the google map coordinates of a given address
 *
 * @param {string} query The address that will be queried
 * @returns {Google.Geocode} A possible coordinate based on the given address
 */
export const fetchGeocodeAddress = async (
  query: string
): Promise<Google.Geocode> => {
  try {
    if (!shouldMock) {
      const { data } = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${query}&key=${
          getENVs?.mapsApiKey ?? ""
        }`
      );
      return data.results?.[0];
    } else {
      const { mockGeocode } = await import("./geo.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockGeocode());
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Fetches the google map places of a given address
 *
 * @param {string} query The address that will be queried
 * @returns {Google.Autocomplete[]} A list of place predictions in response on the given address
 */
export const fetchAutocompletePrediction = async (
  query: string,
  selectedCoordinates: Google.Coordinates,
  isoCode: Country["isoCode"]
): Promise<Google.Autocomplete[]> => {
  try {
    if (!shouldMock) {
      const mapsAPI = window.google.maps;
      if (!mapsAPI) throw new Error("Google API has not been loaded");

      const { places } = mapsAPI;
      const autocompleteService = new places.AutocompleteService();

      const result = await autocompleteService.getPlacePredictions(
        {
          input: query,
          origin: selectedCoordinates,
          componentRestrictions: { country: isoCode }
        },
        (places: Google.AutocompleteResponse) => places
      );

      if (!result) return [];
      return result.predictions;
    } else {
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(genAutocompleteResult());
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Fetches the google map address object of a given coordinate
 *
 * @param {Coordinates} coords The coordinate that will be queried
 * @returns {ReverseGeocodeAddress[]} A list of possible address based on the given coordinate
 */
export const fetchReverseGeocodeAddress = async (
  coords: Google.Coordinates
): Promise<Google.ReverseGeocodeAddress[]> => {
  const { lat, lng } = coords;

  try {
    if (!shouldMock) {
      const { data } = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${
          getENVs?.mapsApiKey ?? ""
        }`
      );
      return data.results;
    } else {
      const { mockReverseGeocodeAddress } = await import("./geo.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockReverseGeocodeAddress);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};
