// Form utility functions
import * as yup from "yup";
import { DocumentType } from "artisn/types";

import { allowedSymbols } from "components/global/PasswordStrength/PasswordStrength.helpers";
import { Rule } from "antd/lib/form";
import { FormCodes, FormCodesType } from "types/atn/form.types";

export const validationMessages = {
  required: "Campo requerido",
  invalidEmail: "Correo electrónico no válido",
  minLength: (min: number) => `El campo debe tener ${min} caracteres mínimo`,
  maxLength: (max: number) => `El campo debe tener ${max} caracteres máximo`,
  onlyNumbers: "El campo debe contener solo números"
};

const { invalidEmail, required, maxLength, minLength } = validationMessages;
const { onlyNumbers } = validationMessages;

export const validationRules = {
  requiredDocumentType: yup.string().required(required),
  email: yup
    .string()
    .email(invalidEmail)
    .min(3, minLength(3))
    .max(50, maxLength(50)),
  requiredString: yup
    .string()
    .required(required)
    .min(3, minLength(3))
    .max(50, maxLength(50))
    .nullable(),
  requiredNumber: yup
    .number()
    .typeError(onlyNumbers)
    .required(required)
    .min(3, minLength(3))
    .max(9999999999999, maxLength(13)),
  requiredBoolean: yup.bool().oneOf([true], required),
  requiredEmail: yup
    .string()
    .required(required)
    .email(invalidEmail)
    .min(3, minLength(3))
    .max(50, maxLength(50)),
  requiredPhoneNumber: yup
    .string()
    .required(required)
    .matches(new RegExp(/^09\d{8}.*$/), "Teléfono ingresado inválido")
    .test(
      "len",
      "El campo debe tener 10 caracteres",
      value => value?.toString().length === 10
    ),
  customPhoneNumber: (min: number, max: number) =>
    yup
      .string()
      .required(required)
      .matches(/^[0-9]+$/, onlyNumbers)
      .min(min, minLength(min))
      .max(max, maxLength(max)),
  minLength: (min: number) =>
    yup.string().required(required).min(min, minLength(min)),
  maxLength: (max: number) =>
    yup.string().required(required).max(max, maxLength(max)),
  customName: (min: number) =>
    yup
      .string()
      .required(required)
      .min(min, minLength(min))
      .max(50, maxLength(50))
      .matches(
        /^[^0-9_!¡?÷?¿/\\+=@#$%^&*(){}|~<>;:[\]]{2,}$/i,
        "El campo debe tener solo letras"
      ),
  password: yup
    .string()
    .min(8, minLength(8))
    .matches(/[A-Z]/, "Debe contener al menos una letra mayúscula")
    .matches(/[0-9]/, "Debe contener al menos un número")
    .matches(
      new RegExp(allowedSymbols),
      "Debe contener al menos un caracter especial"
    ),
  requiredBillingAddress: yup
    .string()
    .required(required)
    .min(3, minLength(3))
    .nullable(),
  twoOrMoreWords: (message?: string) =>
    yup
      .string()
      .required(required)
      .matches(/^[^\d]+$/, "El campo no debe contener números")
      .matches(
        /^[a-zA-Z\u00C0-\u1FFF]+\s+([a-zA-Z\u00C0-\u1FFF]+\s?)+/,
        message ?? "El campo debe contener mínimo dos palabras"
      ),
  documentRules: (documentType: DocumentType | undefined) => {
    switch (documentType) {
      case "CI":
        return yup
          .string()
          .required(required)
          .matches(/^\d+$/, onlyNumbers)
          .test("len", "El campo debe tener 10 caracteres", value => {
            return value?.length === 10;
          })
          .nullable();
      case "RUC":
        return yup
          .string()
          .required(required)
          .matches(/^\d+$/, onlyNumbers)
          .test(
            "len",
            "El campo debe tener 13 caracteres",
            value => value?.length === 13
          )
          .nullable();
      default:
        return yup
          .string()
          .required(required)
          .min(1, minLength(1))
          .max(20, maxLength(20))
          .nullable();
    }
  }
};

export const trimFields = <T>(obj: T): T => {
  if (!obj) return obj;
  return JSON.parse(JSON.stringify(obj).replace(/"\s+|\s+"/g, '"'));
};

export const getMaxLength = (documentType: string | undefined) => {
  switch (documentType) {
    case "CI":
      return 10;
    case "RUC":
      return 13;
    default:
      return 20;
  }
};

// ----- Antd Validations ---

export const antdRequired: Rule = {
  required: true,
  message: "Campo requerido"
};

export const mail: Rule = {
  pattern: new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i),
  required: true,
  message: "Email ingresado invalido"
};

export const phone: Rule = {
  pattern: new RegExp(/^0[2-7](2|3|4|5|6|7|8|9)\d{6}$/),
  required: true,
  message: "Teléfono ingresado invalido"
};

export const mobile: Rule = {
  pattern: new RegExp(/^09\d{8}.*$/),
  required: true,
  message: "Celular ingresado invalido"
};

export const phoneMobile: Rule = {
  pattern: new RegExp(/^0[2-7](2|3|4|5|6|7|8|9)\d{6}$|^09\d{8}$/),
  required: true,
  message: "Teléfono ingresado invalido"
};

export const antdMax = (maxLength: number): Rule => ({
  max: maxLength,
  message: `El campo debe tener máximo ${maxLength} caracteres`
});

export const antdMin = (minLength: number): Rule => ({
  min: minLength,
  message: `El campo debe tener al menos ${minLength} caracteres`
});

export const getFormIdFromCode = (formCode: FormCodesType | undefined) => {
  if (!formCode) {
    throw new Error("formCode is undefined");
  }
  return FormCodes[formCode];
};
