// Company services
import { mockCompanies, mockCompany } from "./company.service.mock";
import { shouldMock } from "utils/common.utils";
import axiosGqlDefault from "utils/axiosgql.utils";
import { buildHeaders } from "utils/services.utils";
import { createCompanyFormQuery } from "./company.service.graphql";
import { updateCompanyFormQuery } from "./company.service.graphql";
import { fetchCompaniesQuery } from "./company.service.graphql";
import { fetchCompanyFormsByFormCodeQuery } from "./company.service.graphql";
import { fetchCompanyFormsQuery } from "./company.service.graphql";
import { fetchUsersAndCompanies } from "./company.service.graphql";
import { updateCompanyStatusQuery } from "./company.service.graphql";
import { fetchCompaniesByUserQuery } from "./company.service.graphql";
import { fetchCompanyFormsbyCompany } from "./company.service.graphql";
import { updateLabelQuery } from "./company.service.graphql";
import { fetchAttributeLabel } from "./company.service.graphql";
import { FetchFormCompanyByCompanyPayload } from "./company.service.types";
import { UpdateCompanyFormPayload } from "./company.service.types";
import { fetchCurrentCompanyQuery } from "./company.service.graphql";
import { Company, UserCompany } from "types/atn/company.types";
import { CreateCompanyFormPayload } from "./company.service.types";
import { FetchCompanyFormPayload } from "./company.service.types";
import { UpdateCompanyStatusPayload } from "./company.service.types";
import { CompanyForm } from "types/atn/company.types";
import { mockAttributeLabel } from "./company.service.mock";
import { AttributeLabel } from "./company.service.types";
import { mockCompanyForm } from "services/atn/company/company.service.mock";
import { mockCompanyForms } from "services/atn/company/company.service.mock";
import { userCompanies } from "./company.service.mock";
import { FetchCompanyFormByFormCodePayload } from "services/atn/company/company.service.types";
import { mockAtribbuteLabel } from "./company.service.mock";

export const fetchCompaniesByUser = async (): Promise<Company[]> => {
  const artisnHeaders = await buildHeaders();

  try {
    if (!shouldMock) {
      const { data } = await axiosGqlDefault.post(
        "",
        {
          query: fetchCompaniesByUserQuery
        },
        {
          headers: {
            authorization: artisnHeaders.Authorization
          }
        }
      );
      // console.log(data);
      if (!data.data.fetchCompaniesByUser) {
        throw new Error(data.errors[0].message);
      }

      return data.data?.fetchCompaniesByUser?.items;
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockCompanies);
        }, 1000);
      });
    }
  } catch (e) {
    console.log(e.message);
    throw new Error(e.message);
  }
};

export const fetchLabelAttribute = async (): Promise<AttributeLabel[]> => {
  const artisnHeaders = await buildHeaders();

  try {
    if (!shouldMock) {
      const { data } = await axiosGqlDefault.post(
        "",
        {
          query: fetchAttributeLabel
        },
        {
          headers: {
            authorization: artisnHeaders.Authorization
          }
        }
      );

      return data.data.formTransList.items;
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockAttributeLabel);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

export const fetchUsersCompanies = async (): Promise<UserCompany[]> => {
  const artisnHeaders = await buildHeaders();

  try {
    if (!shouldMock) {
      const { data } = await axiosGqlDefault.post(
        "",
        {
          query: fetchUsersAndCompanies
        },
        {
          headers: {
            authorization: artisnHeaders.Authorization
          }
        }
      );

      return data.data.usersList.items;
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(userCompanies);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

export const fetchCompanyById = async (companyId: string): Promise<Company> => {
  const artisnHeaders = await buildHeaders();

  try {
    if (!shouldMock) {
      const { data } = await axiosGqlDefault.post(
        "",
        {
          query: fetchCurrentCompanyQuery,
          variables: {
            companyId
          }
        },
        {
          headers: {
            authorization: artisnHeaders.Authorization
          }
        }
      );

      return data.data.company;
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockCompany);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

export const updateCompanyStatus = async (
  updateCompanyStatusPayload: UpdateCompanyStatusPayload
): Promise<Company> => {
  const artisnHeaders = await buildHeaders();
  const { opened, companyId } = updateCompanyStatusPayload;

  try {
    if (!shouldMock) {
      const { data } = await axiosGqlDefault.post(
        "",
        {
          query: updateCompanyStatusQuery,
          variables: {
            data: {
              opened
            },
            filter: {
              id: companyId
            }
          }
        },
        {
          headers: {
            authorization: artisnHeaders.Authorization
          }
        }
      );

      return data.data;
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockCompany);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

export const createCompanyForm = async (
  createCompanyFormPayload: CreateCompanyFormPayload
): Promise<Company> => {
  const artisnHeaders = await buildHeaders();

  try {
    if (!shouldMock) {
      const { data } = await axiosGqlDefault.post(
        "",
        {
          query: createCompanyFormQuery,
          variables: {
            data: {
              ...createCompanyFormPayload
            }
          }
        },
        {
          headers: {
            authorization: artisnHeaders.Authorization
          }
        }
      );

      return data.data;
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockCompany);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

export const updateCompanyForm = async (
  updateCompanyFormPayload: UpdateCompanyFormPayload
): Promise<Company> => {
  const artisnHeaders = await buildHeaders();
  const { formId, ...restPayload } = updateCompanyFormPayload;

  try {
    if (!shouldMock) {
      const { data } = await axiosGqlDefault.post(
        "",
        {
          query: updateCompanyFormQuery,
          variables: {
            data: {
              ...restPayload
            },
            filter: {
              id: formId
            }
          }
        },
        {
          headers: {
            authorization: artisnHeaders.Authorization
          }
        }
      );

      return data.data;
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockCompany);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

export const updateLabel = async (
  idLabel: string | undefined,
  label: string | undefined
): Promise<AttributeLabel> => {
  const artisnHeaders = await buildHeaders();

  try {
    if (!shouldMock) {
      const { data } = await axiosGqlDefault.post(
        "",
        {
          query: updateLabelQuery,
          variables: {
            data: {
              label
            },
            filter: {
              id: idLabel
            }
          }
        },
        {
          headers: {
            authorization: artisnHeaders.Authorization
          }
        }
      );

      return data.data;
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockAtribbuteLabel);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

export const fetchCompanyForm = async (
  fetchCompanyFormPayload: FetchCompanyFormPayload
): Promise<CompanyForm> => {
  const artisnHeaders = await buildHeaders();

  try {
    if (!shouldMock) {
      const { data } = await axiosGqlDefault.post(
        "",
        {
          query: fetchCompanyFormsQuery,
          variables: {
            ...fetchCompanyFormPayload
          }
        },
        {
          headers: {
            authorization: artisnHeaders.Authorization
          }
        }
      );

      return data.data.fetchCompanyForm.items[0];
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockCompanyForm);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

export const fetchCompanyFormByFormCode = async (
  fetchCompanyFormByFormCodePayload: FetchCompanyFormByFormCodePayload
): Promise<CompanyForm> => {
  const artisnHeaders = await buildHeaders();

  try {
    if (!shouldMock) {
      const { data } = await axiosGqlDefault.post(
        "",
        {
          query: fetchCompanyFormsByFormCodeQuery,
          variables: {
            ...fetchCompanyFormByFormCodePayload
          }
        },
        {
          headers: {
            authorization: artisnHeaders.Authorization
          }
        }
      );

      return data.data.fetchCompanyFormByFormCode.items?.[0];
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockCompanyForm);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

export const fetchCompanies = async (): Promise<Company[]> => {
  const artisnHeaders = await buildHeaders();

  try {
    if (!shouldMock) {
      const { data } = await axiosGqlDefault.post(
        "",
        {
          query: fetchCompaniesQuery
        },
        {
          headers: {
            authorization: artisnHeaders.Authorization
          }
        }
      );

      return data.data.companiesList.items;
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockCompanies);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

export const fetchCompanyFormByCompany = async (
  fetchCompanyFormByCompanyPayload: FetchFormCompanyByCompanyPayload
): Promise<CompanyForm[]> => {
  const artisnHeaders = await buildHeaders();

  try {
    if (!shouldMock) {
      const { data } = await axiosGqlDefault.post(
        "",
        {
          query: fetchCompanyFormsbyCompany,
          variables: {
            ...fetchCompanyFormByCompanyPayload
          }
        },
        {
          headers: {
            authorization: artisnHeaders.Authorization
          }
        }
      );
      return data.data.company.companyForms.items;
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockCompanyForms);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};
