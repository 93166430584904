// Stores service hooks
import { useQuery } from "react-query";

import { fetchDefaultStore, fetchStoreDetails } from "./stores.service";
import { fetchNearbyStores, fetchStores } from "./stores.service";
import { Google } from "types/geo.types";
import useGeo from "contexts/geo/geo.hooks";
import useCatalogues from "contexts/catalogues/catalogues.hooks";
import { fetchV1StoreDetails } from "services/stores/stores.service";
import useVendors from "contexts/vendors/vendors.hooks";
import CONSTANTS from "config/constants";

const { ARTISN } = CONSTANTS;
const { STORE_ID } = ARTISN;

export const useFetchDefaultStore = () => {
  const { selectedCatalogueId } = useCatalogues();

  return useQuery(
    ["stores", "default", selectedCatalogueId],
    () => fetchDefaultStore(selectedCatalogueId),
    {
      staleTime: 60 * 1000 * 60
    }
  );
};

export const useFetchStoreDetails = (storeId: number) => {
  return useQuery(["stores", storeId], () => fetchStoreDetails(storeId), {
    staleTime: 60 * 1000 * 60
  });
};

export const useFetchNearbyStores = (coords?: Google.Coordinates) => {
  const { selectedCoordinates } = useGeo();
  const { lat, lng } = coords ?? selectedCoordinates ?? {};
  const { selectedCatalogueId } = useCatalogues();

  return useQuery(
    ["stores", { lat, lng, selectedCatalogueId }],
    () => fetchNearbyStores(lat!, lng!, selectedCatalogueId),
    {
      enabled: typeof lat !== "undefined" && typeof lng !== "undefined"
    }
  );
};

export const useFetchStores = (query?: string) => {
  const { selectedCatalogueId } = useCatalogues();

  return useQuery(["stores", { selectedCatalogueId, query }], () =>
    fetchStores(selectedCatalogueId, query)
  );
};

export const useFetchV1StoreDetails = () => {
  const { selectedVendorId } = useVendors();
  const { selectedCatalogueId } = useCatalogues();

  return useQuery(
    ["stores", STORE_ID],
    () =>
      fetchV1StoreDetails(+STORE_ID, selectedVendorId, +selectedCatalogueId),
    {
      staleTime: 60 * 1000 * 60
    }
  );
};
