import styled from "styled-components";

import { ListItemStyledProps as Props } from "./ListItem.types";

const ListItemStyled = styled.div<Props>`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  column-gap: 1.6rem;
  cursor: pointer;
  flex: 1;

  .ListItem {
    &__title {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2.4rem;
      color: var(--palette-black-s0-l10);
      color: ${props =>
        props.isActive
          ? "var(--palette-primary)"
          : "var(--palette-black-s0-l10)"};
      word-break: break-word;
    }

    &__value {
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      max-width: 100%;
    }

    &__description {
      font-size: 1.2rem;
      color: var(--palette-gray-s0-l40);
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      max-width: 100%;
    }

    &__default {
      font-size: 1.2rem;
    }
  }

  path {
    fill: ${props => (props.isActive ? "var(--palette-primary)" : "initial")};
  }
`;

export default ListItemStyled;
