// Date utility functions
import { Store } from "artisn/types";
import dayjs from "dayjs";

import { WeekDay } from "types/date.types";

export const weekDays: Record<string, WeekDay> = {
  SUNDAY: { es: "Domingo", en: "Sunday", dayOfWeek: 0 },
  MONDAY: { es: "Lunes", en: "Monday", dayOfWeek: 1 },
  TUESDAY: { es: "Martes", en: "Tuesday", dayOfWeek: 2 },
  WEDNESDAY: { es: "Miércoles", en: "Wednesday", dayOfWeek: 3 },
  THURSDAY: { es: "Jueves", en: "Thursday", dayOfWeek: 4 },
  FRIDAY: { es: "Viernes", en: "Friday", dayOfWeek: 5 },
  SATURDAY: { es: "Sábado", en: "Saturday", dayOfWeek: 6 },
  SPECIAL: { es: "Especial", en: "Special", dayOfWeek: 7 }
};

export const transformSecondsToHours = (seconds: number) => {
  let minutes;

  const hours = Math.floor(seconds / 3600);
  minutes = Math.floor((seconds / 60) % 60);
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return hours + ":" + minutes;
};

export const sortSchedule = (
  schedule: Store["schedules"],
  catalogueId: string
) => {
  const filteredSchedule = schedule.filter(
    item => item.catalogueId === catalogueId
  );

  return filteredSchedule.sort((date1, date2) => {
    const a = weekDays[date1.day].dayOfWeek;
    const b = weekDays[date2.day].dayOfWeek;

    if (a > b) {
      return 1;
    }

    if (a < b) {
      return -1;
    }

    return 0;
  });
};

export const getHours = (seconds: number) => {
  return Math.floor(seconds / 3600);
};

export const getMinutes = (seconds: number) => {
  let minutes;

  minutes = Math.floor((seconds / 60) % 60);
  minutes = minutes < 10 ? 0 + minutes : minutes;

  return minutes;
};

// Spanish [es]

const locale = {
  name: "es",
  monthsShort: "ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic".split("_"),
  weekdays: "domingo_lunes_martes_miércoles_jueves_viernes_sábado".split("_"),
  weekdaysShort: "dom._lun._mar._mié._jue._vie._sáb.".split("_"),
  weekdaysMin: "do_lu_ma_mi_ju_vi_sá".split("_"),
  months:
    "enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre".split(
      "_"
    ),
  weekStart: 1,
  formats: {
    LT: "H:mm",
    LTS: "H:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D [de] MMMM [de] YYYY",
    LLL: "D [de] MMMM [de] YYYY H:mm",
    LLLL: "dddd, D [de] MMMM [de] YYYY H:mm"
  },
  relativeTime: {
    future: "en %s",
    past: "hace %s",
    s: "unos segundos",
    m: "un minuto",
    mm: "%d minutos",
    h: "una hora",
    hh: "%d horas",
    d: "un día",
    dd: "%d días",
    M: "un mes",
    MM: "%d meses",
    y: "un año",
    yy: "%d años"
  },
  ordinal: (n: any) => `${n}º`
};

//@ts-ignore
dayjs.locale(locale, null, true);

export default locale;
