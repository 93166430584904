import React, { createContext, useEffect, useMemo, useState } from "react";
import { ContextDevTool } from "react-context-devtool";

import { CompanyProviderProps as Props } from "./company.context.types";
import { CompanyProviderValue } from "./company.context.types";
import { useFetchCompaniesByUser } from "services/atn/company/company.service.hooks";
import { Company } from "types/atn/company.types";
import { Storage } from "@capacitor/storage";
import CONSTANTS from "config/constants";

const { STORAGE } = CONSTANTS;
const { SELECTED_COMPANY_ID } = STORAGE;

// @ts-ignore
export const CompanyContext = createContext<CompanyProviderValue>();

const CompanyProvider: React.FC<Props> = props => {
  const { data: companies } = useFetchCompaniesByUser();
  const [selectedCompany, setSelectedCompany] = useState<Company>();
  const [showWelcomeModal, setShowWelcomeModal] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const { value: companySavedId } = await Storage.get({
          key: SELECTED_COMPANY_ID
        });
        if (!companySavedId) return;
        const companyId = JSON.parse(companySavedId);
        const company = companies?.find(company => company.id === companyId);
        setSelectedCompany(company);
      } catch (e) {
        console.error("Cannot get recent category from storage");
      }
    })();
  }, [companies]);

  const chooseCompany = async (company: Company) => {
    setSelectedCompany(company);
    setShowWelcomeModal(true);
    await Storage.set({
      key: SELECTED_COMPANY_ID,
      value: JSON.stringify(company?.id)
    });
  };

  const value: CompanyProviderValue = useMemo(() => {
    return {
      showWelcomeModal,
      setShowWelcomeModal,
      selectedCompany,
      setSelectedCompany,
      chooseCompany
    };
  }, [selectedCompany, showWelcomeModal]);

  return (
    <CompanyContext.Provider value={value}>
      <ContextDevTool
        context={CompanyContext}
        id="company"
        displayName="Company"
      />
      {props.children}
    </CompanyContext.Provider>
  );
};

export default CompanyProvider;
