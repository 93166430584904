import styled from "styled-components";

import { AddToCartNotificationStyledProps as Props } from "./AddToCartNotification.types";
import Notification from "components/global/notifications/Notification/Notification";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

export const AddToCartNotificationStyled = styled(Notification)<Props>`
  display: grid;
  grid-template-columns: 5.6rem auto 8.8rem;
  align-items: center;

  @media (max-width: ${mobile}px) {
    padding: 1.2rem;
  }

  .AddToCartNotification {
    &__description {
      font-size: 1.2rem;
      color: var(--palette-black-s0-l10);
      padding-left: 0.8rem;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: 100%;
    }

    &__button {
      font-weight: 600;
      font-size: 1.4rem;
      padding: 0;
    }
  }
`;

export const AddToCartNotificationModifiable = styled(Notification)<Props>`
  display: grid;
  align-items: center;
  padding: 2rem 1.4rem;
  border-radius: 1rem;

  @media (max-width: ${mobile}px) {
    padding: 1.2rem;
  }

  .AddToCartNotification {
    &__description {
      margin-bottom: 3.6rem;
      font-size: 3.2rem;
      color: var(--palette-h0s0l20a01);
      font-weight: 500;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: 100%;
    }

    &__buttons {
      display: flex;
      margin-top: 3.6rem;
    }

    &__button {
      height: 5.6rem;
      padding: 0;
      font-weight: 600;
      font-size: 1.6rem;
      border-radius: 0.8rem;

      &.Button {
        margin-right: 1.6rem;
        padding: 0 3.4rem;
        border: none;
        background-color: var(--palette-primary-light);
        color: var(--palette-primary);

        &:hover {
          border: none;
          background-color: var(--palette-primary-light);
          color: var(--palette-primary);
          box-shadow: none;
        }
      }
    }
  }

  .CartPayButton {
    display: flex;
    flex-grow: 1;
  }

  .Cart__summary__button {
    flex-grow: 1;
    height: 5.6rem;
    padding: 0;
    font-weight: 600;
    font-size: 1.6rem;
    border-radius: 0.8rem;
  }

  .Notification__close {
    @media (max-width: ${mobile}px) {
      top: 1.2rem;
      transform: initial;
    }
  }
`;
