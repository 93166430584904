import React from "react";

import Styles from "./AddNewRecordButton.styles";
import { AddNewRecordButtonProps as Props } from "./AddNewRecordButton.types";

import PlusCircleSVG from "../../../../public/assets/images/plus-circle.svg";

const AddNewRecordButton: React.FC<Props> = props => {
  const { value, icon } = props;

  return (
    <Styles className="AddNewRecordButton" {...props}>
      {icon ? icon : <PlusCircleSVG />}
      <div className="AddNewRecordButton__value">{value}</div>
    </Styles>
  );
};

AddNewRecordButton.defaultProps = {};

export default AddNewRecordButton;
