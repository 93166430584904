// BillingData services
import { BaseBillingData, BillingData, Vendor } from "artisn/types";

import axiosDefault from "utils/axios.utils";
import { buildHeaders } from "utils/services.utils";
import { shouldMock } from "utils/common.utils";
import { Country } from "types/country.types";
import { InvoiceSettings } from "types/billing.types";
import { InvoiceSettingsResponse } from "./billingData.service.types";
import { ErrorResponse } from "types/common.types";

const baseUrl = "api/v2/billing-data";

/**
 * Fetches a list of stored billing data of the signed in user.
 *
 * @returns {BillingData[]} The list of billing datas
 */
export const fetchBillingDataList = async (
  countryId: Country["id"]
): Promise<BillingData[]> => {
  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.get(
        `${baseUrl}?countryId=${countryId}`,
        {
          headers: await buildHeaders()
        }
      );
      return data.data;
    } else {
      const { mockBillingDataList } = await import(
        "./billingData.service.mock"
      );
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockBillingDataList());
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Stores a new billing data to the signed in user.
 *
 * @param {BillingData} billingData Billing payload to add
 * @returns {BillingData} the newly added billing data
 */
export const postBillingData = async (
  billingData: BaseBillingData
): Promise<BillingData> => {
  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.post(`${baseUrl}`, billingData, {
        headers: await buildHeaders()
      });
      return data;
    } else {
      const { mockBillingDataPost } = await import(
        "./billingData.service.mock"
      );
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockBillingDataPost(billingData));
        }, 1000);
      });
    }
  } catch (e) {
    if (e.response?.data) {
      const response: ErrorResponse = e.response.data;
      const message = response.warning?.[0].value;
      throw new Error(message);
    } else {
      throw new Error(e.message);
    }
  }
};

/**
 * Updates a billing data of the signed in user.
 *
 * @param {BillingData} billingData a subset of fields to be updated
 * @returns {BillingData} the updated billing data object
 */
export const putBillingData = async (
  billingData: Partial<BillingData>
): Promise<BillingData> => {
  const { id } = billingData;

  if (!id) throw new Error("id not exists");
  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.put(`${baseUrl}/${id}`, billingData, {
        headers: await buildHeaders()
      });

      return data;
    } else {
      const { mockBillingDataPut } = await import("./billingData.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockBillingDataPut(billingData));
        }, 1000);
      });
    }
  } catch (e) {
    if (e.response?.data) {
      const response: ErrorResponse = e.response.data;
      const message = response.warning?.[0].value;
      throw new Error(message);
    } else {
      throw new Error(e.message);
    }
  }
};

/**
 * Deletes a
 *
 * @param {number} id Billing id to delete billing data
 */
export const deleteBillingData = async (id: number): Promise<void> => {
  try {
    if (!shouldMock) {
      await axiosDefault.delete(`${baseUrl}/${id}`, {
        headers: await buildHeaders()
      });
    } else {
      const { mockBillingDataDelete } = await import(
        "./billingData.service.mock"
      );
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockBillingDataDelete(id));
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Fetches information to know whether the billingData is required.
 *
 * @param {number} vendorId Vendor identifier to get the invoice settings
 * @returns {string} Invoice settings
 */
export const fetchInvoiceSettings = async (
  vendorId: Vendor["id"]
): Promise<InvoiceSettings> => {
  const url = `api/vendor?id=${vendorId}&fields=additional_info`;

  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.get<InvoiceSettingsResponse>(url);
      const { additional_info: invoiceSettings } = data ?? {};
      if (!invoiceSettings) {
        throw new Error(
          `There are no invoice settings set to this vendor ${vendorId}`
        );
      }
      return invoiceSettings;
    } else {
      const { mockInvoiceSettings } = await import(
        "./billingData.service.mock"
      );
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockInvoiceSettings);
        }, 1000);
      });
    }
  } catch (error) {
    if (error.isAxiosError) {
      throw new Error(
        "No se ha podido obtener las configuraciones de los datos de facturación"
      );
    } else {
      throw new Error(error.message);
    }
  }
};
