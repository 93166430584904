import React from "react";

import Styles from "./Button.styles";
import { ButtonProps as Props } from "./Button.types";

const Button: React.FC<Props> = props => {
  return <Styles {...props} />;
};

Button.defaultProps = {};

export default Button;
