import styled from "styled-components";

import { CheckboxStyledProps as Props } from "./Checkbox.types";

const CheckboxStyled = styled.div<Props>`
  .Checkbox {
    &__container {
      display: flex;
      width: fit-content;
      align-items: center;
    }

    &__check {
      min-width: 2.4rem;
      min-height: 2.4rem;
      border: 0.1rem solid var(--palette-h0s0l20a01);
      border-radius: 0.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      &--active {
        background-color: var(--palette-h270s8l15a1);
      }
    }

    &__errorMessage {
      display: inline-block;
      margin-top: 0.4rem;
      font-size: 1.2rem;
      color: var(--palette-h355s90l55a1);
    }

    &__input {
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      cursor: pointer;
    }

    &__label {
      cursor: pointer;
      padding-left: 0.8rem;
    }

    &__icon {
      margin: 0;
    }
  }
`;

export default CheckboxStyled;
