import React, { createContext, useMemo, useState } from "react";
import { ContextDevTool } from "react-context-devtool";
import { BillingData } from "artisn/types";

import { BillingDataProviderProps as Props } from "./billingData.context.types";
import { BillingDataProviderValue } from "./billingData.context.types";

// @ts-ignore
export const BillingDataContext = createContext<BillingDataProviderValue>({});

const BillingDataProvider: React.FC<Props> = props => {
  const [selectedBillingData, setSelectedBillingData] = useState<BillingData>();
  const [wantBill, setWantBill] = useState(true);
  const [companyEmail, setCompanyEmail] = useState("");

  const resetBillingContext = () => {
    setSelectedBillingData(undefined);
    setWantBill(true);
  };

  const value: BillingDataProviderValue = useMemo(() => {
    return {
      selectedBillingData,
      setSelectedBillingData,
      resetBillingContext,
      wantBill,
      setWantBill,
      companyEmail,
      setCompanyEmail
    };
  }, [companyEmail, selectedBillingData, wantBill]);

  return (
    <BillingDataContext.Provider value={value}>
      <ContextDevTool
        context={BillingDataContext}
        id="billingData"
        displayName="BillingData"
      />
      {props.children}
    </BillingDataContext.Provider>
  );
};

export default BillingDataProvider;
