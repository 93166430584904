// Store utilities functions and data

export const getStoreDistanceUnit = (distance?: number) => {
  if (!distance) return "";
  const moreThanOneKm = distance > 1000;
  const newDistance = moreThanOneKm
    ? Number(distance / 1000).toFixed(0)
    : distance;
  const unit = moreThanOneKm ? "km" : "m";

  return `A ${newDistance} ${unit}`;
};
