// Theme utility functions
import { Storage } from "@capacitor/storage";

import CONSTANTS from "config/constants";

const { THEME_PREFERENCE_TOKEN } = CONSTANTS.STORAGE;

export const isDarkThemePreferred = async () => {
  if (typeof window === "undefined") return false;
  try {
    const isSystemDark =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;
    const localStorageTheme = await Storage.get({
      key: THEME_PREFERENCE_TOKEN
    });
    const localStorageDark = localStorageTheme.value === "dark";
    if (localStorageTheme.value === null) return isSystemDark;

    return localStorageDark;
  } catch (e) {
    console.error(e.message);
    return false;
  }
};

/* Only works for initial values, it doesn't take into consideration
 * local storage preference
 */
export const isDarkThemePreferredSync = () => {
  if (typeof window === "undefined") return false;
  return (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  );
};
