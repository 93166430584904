import styled from "styled-components";

import { StoreListStyledProps as Props } from "./StoreList.types";

const StoreListStyled = styled.div<Props>`
  background-color: var(--palette-white);
  flex: 1;
  display: flex;
  flex-direction: column;

  .StoreList {
    &__empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;

      &__title {
        color: var(--palette-black-s0-l10);
        font-size: 2.4rem;
        padding-top: 1.6rem;
      }

      &__message {
        color: var(--palette-gray-s0-l40);
        font-size: 1.6rem;
        padding-top: 1.2rem;
      }
    }

    &__error {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;

      &__message {
        font-size: 1.6rem;
        padding-top: 1.6rem;
        color: var(--palette-red-s60-l60);
      }
    }
  }
`;

export default StoreListStyled;
