import React from "react";

import { ArtisnProviderProps as Props } from "./ArtisnProvider.types";
import AuthProvider from "contexts/auth/auth.context";
import CountriesProvider from "contexts/countries/countries.context";
import PaymentsProvider from "contexts/payments/payments.context";
import BillingDataProvider from "contexts/billingData/billingData.context";
import StoresProvider from "contexts/stores/stores.context";
import ProductsProvider from "contexts/products/products.context";
import GeoProvider from "contexts/geo/geo.context";
import ShippingAddressProvider from "contexts/shippingAddress/shippingAddress.context";
import ShoppingCartProvider from "contexts/shoppingCart/shoppingCart.context";
import CataloguesProvider from "contexts/catalogues/catalogues.context";
import AnalyticsProvider from "contexts/analytics/analytics.context";
import VendorsProvider from "contexts/vendors/vendors.context";
import CheckoutProvider from "components/checkout/Checkout/context/checkout/checkout.context";
import TalkShopProvider from "contexts/talkShop/talkShop/talkShop.context";

const ArtisnProvider: React.FC<Props> = props => {
  const { children } = props;

  return (
    <AnalyticsProvider>
      <AuthProvider>
        <CountriesProvider>
          <VendorsProvider>
            <PaymentsProvider>
              <BillingDataProvider>
                <StoresProvider>
                  <ProductsProvider>
                    <GeoProvider>
                      <ShippingAddressProvider>
                        <ShoppingCartProvider>
                          <CataloguesProvider>
                            <CheckoutProvider>
                              <TalkShopProvider>{children}</TalkShopProvider>
                            </CheckoutProvider>
                          </CataloguesProvider>
                        </ShoppingCartProvider>
                      </ShippingAddressProvider>
                    </GeoProvider>
                  </ProductsProvider>
                </StoresProvider>
              </BillingDataProvider>
            </PaymentsProvider>
          </VendorsProvider>
        </CountriesProvider>
      </AuthProvider>
    </AnalyticsProvider>
  );
};

ArtisnProvider.defaultProps = {};

export default ArtisnProvider;
