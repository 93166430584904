import { useMutation, useQuery, useQueryClient } from "react-query";

import { auth } from "config/artisn.config";
import { fetchCompaniesByUser } from "./company.service";
import useAuth from "contexts/auth/auth.context.hooks";
import { createCompanyForm } from "services/atn/company/company.service";
import { fetchCompanyForm } from "services/atn/company/company.service";
import { fetchCompanyFormByFormCode } from "services/atn/company/company.service";
import { fetchCompanies } from "services/atn/company/company.service";
import { fetchCompanyFormByCompany } from "services/atn/company/company.service";
import { fetchUsersCompanies } from "./company.service";
import { fetchCompanyById } from "services/atn/company/company.service";
import { updateCompanyForm } from "services/atn/company/company.service";
import { updateCompanyStatus } from "./company.service";
import { fetchLabelAttribute } from "./company.service";

export const useFetchCompaniesByUser = () => {
  const { uid } = useAuth();
  const { isAnonymous } = auth()?.currentUser ?? {};

  return useQuery(["company", uid], () => fetchCompaniesByUser(), {
    enabled: isAnonymous === undefined ? false : !isAnonymous
  });
};

export const useFetchUsersCompanies = () => {
  const { isAnonymous, uid } = useAuth();

  return useQuery(["user", uid], () => fetchUsersCompanies(), {
    enabled: !isAnonymous
  });
};

export const useFetchLabel = () => {
  const { isAnonymous, uid } = useAuth();

  return useQuery(["label", uid], () => fetchLabelAttribute(), {
    enabled: !isAnonymous
  });
};

export const useFetchCompanyById = (companyId: string | undefined) => {
  const { isAnonymous } = useAuth();

  return useQuery(
    ["companyId", companyId],
    () => fetchCompanyById(companyId!),
    {
      enabled: !isAnonymous && !!companyId
    }
  );
};

export const useUpdateCompanyStatus = (
  companyId: string | undefined,
  opened: boolean | undefined
) => {
  const queryClient = useQueryClient();

  return useMutation(updateCompanyStatus, {
    onSettled: () => {
      queryClient.invalidateQueries(["CompanyStatus", companyId]);
      queryClient.invalidateQueries(["forms", companyId]);
    }
  });
};

// export const useUpdateLabelAttribute = (
//   id: string | undefined,
//   label: string | undefined
// ) => {
//   const queryClient = useQueryClient();

//   return useMutation(updateLabel, {
//     onSettled: () => {
//       queryClient.invalidateQueries(["label", id, label]);
//     }
//   });
// };

export const useCreateCompanyForm = (
  companyId: string | undefined,
  formId: string | undefined,
  formCategoryId: string | string[] | undefined
) => {
  const queryClient = useQueryClient();

  return useMutation(createCompanyForm, {
    onSettled: () => {
      queryClient.invalidateQueries(["companyForm", companyId, formId]);
      queryClient.invalidateQueries(["forms", companyId, formCategoryId]);
    }
  });
};

export const useUpdateCompanyForm = (
  companyId: string | undefined,
  formId: string | undefined,
  formCategoryId: string | string[] | undefined
) => {
  const queryClient = useQueryClient();

  return useMutation(updateCompanyForm, {
    onSettled: () => {
      queryClient.invalidateQueries(["companyForm", companyId, formId]);
      queryClient.invalidateQueries(["forms", companyId, formCategoryId]);
    }
  });
};

export const useFetchCompanyForm = (
  companyId: string | undefined,
  formId: string | undefined
) => {
  const { isAnonymous } = useAuth();

  return useQuery(
    ["companyForm", companyId, formId],
    () =>
      fetchCompanyForm({
        companyId: companyId!,
        formId: formId!
      }),
    {
      enabled: !!companyId && !!formId && !isAnonymous
    }
  );
};

export const useFetchCompanyFormByFormCode = (
  companyId: string | undefined,
  formCode: string | undefined
) => {
  const { isAnonymous } = useAuth();

  return useQuery(
    ["companyForm", companyId, formCode],
    () =>
      fetchCompanyFormByFormCode({
        companyId: companyId!,
        formCode: formCode!
      }),
    {
      enabled: !!companyId && !!formCode && !isAnonymous
    }
  );
};

export const useFetchCompanies = () => {
  const { isAnonymous, uid } = useAuth();

  return useQuery(["companies", uid], () => fetchCompanies(), {
    enabled: !isAnonymous
  });
};

export const useFetchCompanyFormByCompany = (companyId: string | undefined) => {
  const { isAnonymous } = useAuth();

  return useQuery(
    ["companyForm", companyId],
    () =>
      fetchCompanyFormByCompany({
        companyId: companyId!
      }),
    {
      enabled: !!companyId && !isAnonymous
    }
  );
};
