import styled from "styled-components";
import { Modal } from "artisn-ui-react";

import { ModalStyledProps as Props } from "./Modal.types";
import { getMaxHeight, getMaxWidth } from "utils/styling.utils";

const ModalStyled = styled(Modal)<Props>`
  max-width: ${getMaxWidth()};
  max-height: ${getMaxHeight()};

  @media all and (hover: none) {
    width: ${getMaxWidth()};
    height: ${getMaxHeight()};
  }

  .Modal {
  }
`;

export default ModalStyled;
