import React from "react";
import { AppProps as Props } from "next/app";
import Script from "next/script";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "artisn-ui-react/main.css";
import "antd/dist/antd.css";

import Providers from "containers/Providers/Providers";
import Layout from "components/global/Layout/Layout";

ChartJS.register(ArcElement, Tooltip, Legend);

const App: React.FC<Props> = props => {
  const { Component, pageProps } = props;

  return (
    <Providers dehydratedState={pageProps.dehydratedState}>
      <Layout>
        <Script id="google-tag-manager" strategy="afterInteractive">
          {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-57S39QP');
      `}
        </Script>
        <Component {...pageProps} />
      </Layout>
    </Providers>
  );
};

export default App;
