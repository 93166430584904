// ShippingCost service hooks
import { useQuery } from "react-query";

import { fetchShippingCost } from "./shippingCost.service";
import { UseFetchShippingCostConfig } from "./shippingCost.service.types";
import useShoppingCart from "contexts/shoppingCart/shoppingCart.context.hooks";

export const useFetchShippingCost = (config: UseFetchShippingCostConfig) => {
  const { catalogueId, storeId, lat, lng } = config;
  const { shoppingCart } = useShoppingCart();

  return useQuery(
    ["shippingCost", config],
    () =>
      fetchShippingCost({
        catalogueId: catalogueId!,
        storeId: storeId!,
        lat: lat!,
        lng: lng!
      }),
    {
      enabled:
        typeof catalogueId !== "undefined" &&
        typeof storeId !== "undefined" &&
        typeof lat !== "undefined" &&
        typeof lng !== "undefined" &&
        !!shoppingCart
    }
  );
};
