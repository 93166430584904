import styled from "styled-components";

import { HorizontalProductCardStyledProps as Props } from "./HorizontalProductCard.types";

const HorizontalProductCardStyled = styled.div<Props>`
  .HorizontalProductCard {
    &__product-details {
      display: grid;
      grid-template: 1.8rem auto auto / 10rem 1fr;
      grid-template-areas:
        "image name total"
        "image description price";
      column-gap: 0.8rem;
    }

    &__image {
      grid-area: image;
    }

    &__name {
      grid-area: name;
      font-size: 1.2rem;
    }

    &__description {
      display: block;
      grid-area: description;
      font-size: 1.2rem;
      color: #1111117a;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__price {
      align-self: flex-start;
      grid-area: price;

      .ProductBasePriceInfo--normal {
        font-size: 1.2rem;
        font-weight: 400;
        color: var(--palette-gray-s0-l35);
        line-height: 1.6rem;
      }
    }

    &__total {
      align-self: flex-start;
      grid-area: total;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
`;

export default HorizontalProductCardStyled;
