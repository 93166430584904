// ShippingCost services

import { ShippingCost } from "artisn/types";

import { shouldMock } from "utils/common.utils";
import axiosDefault from "utils/axios.utils";
import { buildHeaders } from "utils/services.utils";
import { FetchShippingCostPayload } from "./shippingCost.service.types";

/**
 * Fetches the shipping cost for a given store based on a coordinate.
 *
 * @param {FetchShippingCostPayload} config The configuration needed to fetch
 * @returns {ShippingCost} A shippingCost object
 */
export const fetchShippingCost = async (
  config: FetchShippingCostPayload
): Promise<ShippingCost> => {
  const { storeId, catalogueId, lat, lng } = config;
  const baseUrl = `/api/shippingCost?stores=${storeId}&lat=${lat}&lng=${lng}&channelId=${catalogueId}`;

  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.get(baseUrl, {
        headers: await buildHeaders()
      });

      return data.data;
    } else {
      const { mockShippingCost } = await import("./shippingCost.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockShippingCost);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(
      `Failed to fetch shipping cost from store ${storeId}.\n${e.message}`
    );
  }
};
