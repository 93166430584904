import React, { createContext, useMemo, useState } from "react";
import { ContextDevTool } from "react-context-devtool";

import { PaymentsProviderProps as Props } from "./payments.context.types";
import { PaymentProviderId } from "./payments.context.types";
import { PaymentMethodId } from "./payments.context.types";
import { PaymentsProviderValue } from "./payments.context.types";
import { Card } from "types/payment.types";

// @ts-ignore
export const PaymentsContext = createContext<PaymentsProviderValue>();

const PaymentsProvider: React.FC<Props> = props => {
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(
    PaymentMethodId.OTHER
  );
  const [selectedPaymentProviderId, setSelectedPaymentProviderId] =
    useState<PaymentProviderId>();
  const [selectedCard, setSelectedCard] = useState<Card>();

  const resetPaymentsContext = () => {
    setSelectedPaymentMethodId(PaymentMethodId.OTHER);
    setSelectedPaymentProviderId(undefined);
    setSelectedCard(undefined);
  };

  const value: PaymentsProviderValue = useMemo(() => {
    return {
      resetPaymentsContext,
      selectedCard,
      setSelectedCard,
      selectedPaymentMethodId,
      setSelectedPaymentMethodId,
      selectedPaymentProviderId,
      setSelectedPaymentProviderId
    };
  }, [selectedCard, selectedPaymentMethodId, selectedPaymentProviderId]);

  return (
    <PaymentsContext.Provider value={value}>
      <ContextDevTool
        context={PaymentsContext}
        id="payments"
        displayName="Payments"
      />
      {props.children}
    </PaymentsContext.Provider>
  );
};

export default PaymentsProvider;
