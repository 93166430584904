// Address service hooks
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ShippingAddress } from "artisn/types";
import { Storage } from "@capacitor/storage";

import { postShippingAddress } from "./shippingAddress.service";
import { deleteShippingAddress } from "./shippingAddress.service";
import { putShippingAddress } from "./shippingAddress.service";
import { fetchShippingAddresses } from "./shippingAddress.service";
import useAuth from "contexts/auth/auth.context.hooks";
import useShippingAddress from "contexts/shippingAddress/shippingAddress.hooks";
import CONSTANTS from "config/constants";
import { PutShippingAddressPayload } from "./shippingAddress.service.types";
import { PostShippingAddressPayload } from "./shippingAddress.service.types";

const { SHIPPING_ADDRESS_TOKEN } = CONSTANTS.STORAGE;

/** Hook to get user's addresses.
 *
 * @returns {UseQueryResult<Address[]>} Returns a use query result with the address data
 */
export const useFetchShippingAddresses = () => {
  const { uid, isAnonymous } = useAuth();
  return useQuery([uid, "addresses"], () => fetchShippingAddresses(), {
    enabled: !isAnonymous && !!uid,
    staleTime: 30 * 1000 * 60
  });
};

/** Hook to add address to user.
 *
 * @returns {UseMutationResult<ShippingAddress, Error, postShippingAddressRequestPayload,ShippingAddress[]>} Returns a use mutation result to add address
 */
export const usePostShippingAddress = () => {
  const queryClient = useQueryClient();
  const { uid } = useAuth();
  const { setSelectedShippingAddress } = useShippingAddress();

  return useMutation<
    ShippingAddress | undefined,
    Error,
    PostShippingAddressPayload
  >(postShippingAddress, {
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries([uid, "addresses"]);
    },
    onSuccess: setSelectedShippingAddress,
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, newAddress, previousAddresses) => {
      if (previousAddresses) {
        queryClient.setQueryData([uid, "addresses"], previousAddresses);
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries([uid, "addresses"]);
    }
  });
};

/** Hook to update address to user.
 *
 * @returns {UseMutationResult<ShippingAddress, Error, {postShippingAddressRequestPayload,  number}, ShippingAddress[]>} Returns a use mutation result to update address
 */
export const usePutShippingAddress = () => {
  const queryClient = useQueryClient();
  const { uid } = useAuth();
  const { setSelectedShippingAddress } = useShippingAddress();

  return useMutation<
    ShippingAddress | undefined,
    Error,
    PutShippingAddressPayload,
    ShippingAddress[] | undefined
  >(putShippingAddress, {
    onMutate: async updatedShippingAddress => {
      await queryClient.cancelQueries([uid, "addresses"]);

      const previousAddresses = queryClient.getQueryData<ShippingAddress[]>([
        uid,
        "addresses"
      ]);

      // Optimistically update to the new value
      if (previousAddresses) {
        const newAddress = [...previousAddresses];
        const id = newAddress.findIndex(
          address => address.id === updatedShippingAddress.id
        );
        newAddress[id] = updatedShippingAddress as ShippingAddress;
        queryClient.setQueryData<ShippingAddress[]>(
          [uid, "addresses"],
          newAddress
        );
      }

      return previousAddresses;
    },
    onSuccess: setSelectedShippingAddress,
    onError: (error, address, previousAddresses) => {
      if (previousAddresses) {
        queryClient.setQueryData([uid, "addresses"], previousAddresses);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([uid, "addresses"]);
    }
  });
};

/** Hook to delete address to user.
 *
 * @returns {UseMutationResult<void, Error, number, ShippingAddress[]>} Returns a use mutation result to delete address
 */
export const useDeleteShippingAddress = () => {
  const queryClient = useQueryClient();
  const { uid } = useAuth();
  const { selectedShippingAddress } = useShippingAddress();
  const { setSelectedShippingAddress } = useShippingAddress();

  return useMutation<void, Error, number, ShippingAddress[] | undefined>(
    deleteShippingAddress,
    {
      onMutate: async addressId => {
        await queryClient.cancelQueries([uid, "addresses"]);

        const previousAddresses = queryClient.getQueryData<ShippingAddress[]>([
          uid,
          "addresses"
        ]);

        // Optimistically update to the new value
        if (previousAddresses) {
          queryClient.setQueryData<ShippingAddress[]>(
            [uid, "addresses"],
            () => {
              const filterAddresses = previousAddresses.filter(
                address => address.id !== addressId
              );

              return filterAddresses;
            }
          );
        }

        return previousAddresses;
      },
      onSuccess: (data, addressId) => {
        if (selectedShippingAddress?.id === addressId) {
          setSelectedShippingAddress(undefined);
          Storage.remove({ key: SHIPPING_ADDRESS_TOKEN });
        }
      },
      onError: (error, addressId, previousAddresses) => {
        if (previousAddresses) {
          queryClient.setQueryData([uid, "addresses"], previousAddresses);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries([uid, "addresses"]);
      }
    }
  );
};
