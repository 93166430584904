import React, { useState } from "react";

import Styles from "./ShoppingCartProduct.styles";
import { ShoppingCartProductProps as Props } from "./ShoppingCartProduct.types";
import HorizontalProductCard from "components/global/HorizontalProductCard/HorizontalProductCard";
import Counter from "components/global/Counter/Counter";

import DeleteSVG from "../../../../public/assets/images/delete.svg";
import InfoCircleSVG from "../../../../public/assets/images/info.svg";

const ShoppingCartProduct: React.FC<Props> = props => {
  const {
    product,
    initialQuantity = 0,
    onChangeQuantity,
    onDelete,
    disabled,
    onClick,
    modifiers,
    minCounter = 1,
    onAdd,
    onSubtract,
    ...rest
  } = props;
  const { available } = product ?? {};
  const [count, setCount] = useState(minCounter);

  const onChange = (value: number) => {
    setCount(value);
    onChangeQuantity(value);
  };

  return (
    <Styles className="ShoppingCartProduct" {...rest} count={count}>
      <HorizontalProductCard
        product={product}
        onClick={onClick}
        modifiers={modifiers}
      />
      <div className="ShoppingCartProduct__modifyProduct">
        {onDelete ? (
          <button
            className="ShoppingCartProduct__removeButton"
            onClick={onDelete}
          >
            <DeleteSVG />
          </button>
        ) : null}
        {!available ? (
          <p className="ShoppingCartProduct__error">
            <InfoCircleSVG viewBox="0 0 19 20" />
            Este producto no está disponible
          </p>
        ) : null}
        <Counter
          className="ShoppingCartProduct__counter"
          initialValue={initialQuantity}
          onChange={onChange}
          onAdd={onAdd}
          onSubtract={onSubtract}
          disabled={disabled}
          min={minCounter}
        />
      </div>
    </Styles>
  );
};

ShoppingCartProduct.defaultProps = {};

export default ShoppingCartProduct;
