import React, { useEffect, useState } from "react";
import { StaticMap, Clickable, Coordinates } from "artisn-ui-react";
import { ShippingAddress } from "artisn/types";
import { Coords } from "google-map-react";

import Styles from "./AddressFormModal.styles";
import { AddressFormModalProps as Props } from "./AddressFormModal.types";
import AddressForm from "components/profileAddresses/AddressForm/AddressForm";
import Modal from "components/global/Modal/Modal";
import Button from "components/global/Button/Button";
import { getENVs } from "config/artisn.config";
import SearchAddress from "components/global/SearchAddress/SearchAddress";
import MapAddressPreview from "components/profileAddresses/MapAddressPreview/MapAddressPreview";
import { Google } from "types/geo.types";
import ChooseAddressInMap from "components/chooseAddressInMap/ChooseAddressInMap/ChooseAddressInMap";
import { isTouchScreenDevice } from "utils/common.utils";
import { defaultFunction, getMapSize } from "utils/common.utils";
import { AddressFormValues } from "../AddressForm/AddressForm.types";
import AddressList from "../AddressList/AddressList";
import { usePutShippingAddress } from "services/shippingAddress/shippingAddress.service.hooks";
import useWindowSize from "hooks/useWindowSize";
import { getMaxWidth } from "utils/styling.utils";
import CONSTANTS from "config/constants";
import { dismissErrorNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";
import { useFetchCoordsFromGoogleAddress } from "services/geo/geo.service.hooks";
import { useFetchGoogleAddressFromCoords } from "services/geo/geo.service.hooks";
import { useFetchNearbyStores } from "services/stores/stores.service.hooks";
import useGeo from "contexts/geo/geo.hooks";
import useAuth from "contexts/auth/auth.context.hooks";
import { trimFields } from "utils/form.utils";

import CloseSVG from "../../../../public/assets/images/close.svg";
import MapSVG from "../../../../public/assets/images/map.svg";
import EmptySVG from "../../../../public/assets/images/no-location.svg";
import ChevronLeftSVG from "../../../../public/assets/images/chevron-left-black.svg";

const { tablet } = CONSTANTS.BREAKPOINTS;

const AddressFormModal: React.FC<Props> = props => {
  const { opened, editAddress, locationOnly, onDismiss } = props;
  const { onClose = defaultFunction } = props;
  const [predictedPlaces, setPredictedPlaces] =
    useState<Google.Autocomplete[]>();
  const [initialValues, setInitialValues] = useState<AddressFormValues>();
  const [mapAddress, setMapAddress] = useState<Google.Geocode>();
  const { geometry } = mapAddress! ?? {};
  const { location } = geometry ?? {};
  const [coordinates, setCoordinates] = useState<Coordinates>(location);
  const [formattedAddress, setFormattedAddress] = useState("");
  const { isAnonymous } = useAuth();
  const [step, setStep] = useState(isAnonymous ? 3 : 1);
  const [prevStep, setPrevStep] = useState(isAnonymous ? 3 : 1);
  const { mutate: putMutate, reset: putReset } = usePutShippingAddress();
  const { width, height } = useWindowSize();
  const { height: mapHeight } = getMapSize(width, height);
  const isMobile = width <= tablet;
  const maxWidth = +getMaxWidth().replace("px", "");
  const padding = isMobile ? 32 : 64;
  const isTouchDevice = typeof isTouchScreenDevice() === "boolean";
  const [selectedPlaceName, setSelectedPlaceName] = useState("");
  const [result, setResult] = useState("");
  const { data: place } = useFetchCoordsFromGoogleAddress(selectedPlaceName);
  const { selectedCoordinates, deviceCoordinates } = useGeo();
  const { data: googleAddressFromCoords } =
    useFetchGoogleAddressFromCoords(selectedCoordinates);
  const { setSelectedCoordinates } = useGeo();
  const { data: stores } = useFetchNearbyStores(
    coordinates ?? selectedCoordinates
  );
  const noCoverage = !stores?.length;

  const handlerMapSearch = () => {
    setStep(3);
    setPrevStep(1);
  };

  const selectedCoordinatesHandler = (coordinates: Coords) => {
    setSelectedCoordinates(coordinates);
    setCoordinates(coordinates);
    setStep(2);
    onDismiss?.();
    if (locationOnly) {
      onClose();
      clearModal();
    }
  };

  const addressInMapHandler = (address: Google.Geocode) => {
    setMapAddress(address);
    setStep(2);
  };

  const addressInMapGoBackHandler = () => {
    if (prevStep === 1) setStep(1);
    if (prevStep === 2) setStep(2);
  };

  const addressFormGoBackHandler = () => {
    setSelectedPlaceName("");
    if (isAnonymous) setStep(3);
    else setStep(1);
  };

  const editAddressInMapHandler = () => {
    if (mapAddress) {
      setCoordinates(location);
      setPrevStep(2);
      setStep(3);
      return;
    }
    if (editAddress) {
      const { lat, lng } = editAddress;
      setCoordinates({ lat, lng });
      setPrevStep(2);
      setStep(3);
    }
  };

  const onEditAddress = (address: ShippingAddress) => {
    const enhancedAddress: ShippingAddress = {
      ...address,
      default: true
    };
    const newAddress = trimFields(enhancedAddress);
    putMutate(newAddress, {
      onError: () => {
        dismissErrorNotification();
        createErrorNotification(
          "Se produjo un error al intentar actualizar los datos de la dirección."
        );
        console.error("An error occurred in the POST request");
      },
      onSuccess: () => {
        onClose();
        clearModal();
        putReset();
      }
    });
  };

  const clearModal = () => {
    setStep(isAnonymous ? 3 : 1);
    setPrevStep(1);
    setInitialValues(undefined);
    setSelectedPlaceName("");
    setResult("");
    setPredictedPlaces(undefined);
  };

  useEffect(() => {
    if (opened && deviceCoordinates && !mapAddress && !editAddress) {
      setCoordinates(deviceCoordinates);
    }
  }, [opened, deviceCoordinates, isAnonymous, mapAddress, editAddress]);

  useEffect(() => {
    if (!editAddress) return;
    const { lat, lng, number: addressNumber, livingPlace } = editAddress ?? {};
    const { fields } = livingPlace;
    const { label } = fields[0];
    setCoordinates({ lat, lng });
    setFormattedAddress(label);
    setInitialValues({
      ...editAddress,
      addressNumber
    });
    setStep(2);
    setPrevStep(2);
  }, [editAddress]);

  useEffect(() => {
    if (!mapAddress) return;
    const { geometry, formatted_address } = mapAddress;
    const { location } = geometry;
    setCoordinates(location);
    setFormattedAddress(formatted_address);
  }, [mapAddress]);

  useEffect(() => {
    if (!place) return;
    setMapAddress(place);
    setStep(2);
    setPrevStep(1);
  }, [place]);

  useEffect(() => {
    const [place] = googleAddressFromCoords ?? [];
    if (!place || !isAnonymous) return;
    setMapAddress(place);
    if (opened) return;
    setStep(3);
    setPrevStep(1);
  }, [googleAddressFromCoords, isAnonymous, opened]);

  const addressFormNode = (
    <div className="AddressFormModal__address-form">
      <h1 className="AddressFormModal__title AddressFormModal__title--fixed">
        {!editAddress ? (
          <Clickable
            className="AddressFormModal__icon AddressFormModal__go-back"
            onClick={addressFormGoBackHandler}
          >
            <ChevronLeftSVG />
          </Clickable>
        ) : null}
        Completa tu Dirección
        <Clickable
          className="AddressFormModal__icon AddressFormModal__close"
          onClick={() => {
            onClose();
            clearModal();
          }}
        >
          <CloseSVG />
        </Clickable>
      </h1>
      <StaticMap
        googleMapsKey={`${getENVs?.mapsApiKey}`}
        width={isTouchDevice ? maxWidth - padding : 600 - padding}
        height={mapHeight}
        className="AddressFormModal__static-map"
        markers={[
          {
            coordinates,
            icon: "https://res.cloudinary.com/dphyzfzpu/image/upload/v1629756397/Simple/Map-point_Copy_5_mol5yb.png"
          }
        ]}
      />
      {noCoverage ? (
        <p className="AddressFormModal__coverage">
          Tu dirección está fuera de cobertura, intenta cambiarla
        </p>
      ) : null}
      <div className="AddressFormModal__header">
        <div className="AddressFormModal__info">
          <p className="AddressFormModal__info__title">Tu posición actual</p>
          <p className="AddressFormModal__info__position">{formattedAddress}</p>
        </div>
        <Button
          type="BORDER"
          color="black"
          className="AddressFormModal__header__button"
          onClick={editAddressInMapHandler}
        >
          Editar ubicación
        </Button>
      </div>
      <AddressForm
        mapAddress={mapAddress!}
        method={editAddress ? "PUT" : "POST"}
        initialValues={initialValues}
        onPressButton={() => {
          onClose();
          clearModal();
        }}
        disabled={noCoverage}
        editAddress={editAddress}
      />
    </div>
  );

  const mapAddressPreviewNode = (
    <div className="AddressFormModal__map-preview">
      <h1 className="AddressFormModal__title AddressFormModal__title--fixed">
        Dirección de entrega
        <Clickable
          className="AddressFormModal__icon AddressFormModal__close"
          onClick={() => {
            onClose();
            clearModal();
          }}
        >
          <CloseSVG />
        </Clickable>
      </h1>
      <SearchAddress
        onPredictedPlaces={setPredictedPlaces}
        showSearchDropdown={false}
        onInputChange={() => setResult("")}
      />
      <div className="AddressFormModal__map-search" onClick={handlerMapSearch}>
        <MapSVG /> Encontrar dirección en el mapa
      </div>
      <div className="AddressFormModal__places">
        {predictedPlaces?.length === 0 ? (
          <div className="AddressFormModal__empty">
            <div className="AddressFormModal__empty__icon">
              <EmptySVG />
            </div>
            <div className="AddressFormModal__empty__title">
              No se encontraron resultados
            </div>
          </div>
        ) : null}
        {predictedPlaces?.length || result ? (
          <AddressList
            className="AddressFormModal__address-list"
            searchResult={result || predictedPlaces}
            onEdit={onEditAddress}
            dropdownFixed={false}
          />
        ) : null}
        {predictedPlaces?.map((item, index) => {
          const { description } = item;
          return (
            <MapAddressPreview
              key={index}
              address={description}
              onClick={() => setSelectedPlaceName(description)}
              className="AddressFormModal__map-address-preview"
            />
          );
        })}
        {result ? (
          <MapAddressPreview
            address={result}
            onClick={() => setSelectedPlaceName(result)}
            className="AddressFormModal__map-address-preview"
          />
        ) : null}
      </div>
    </div>
  );

  const chooseAddressInMapNode = (
    <div className="AddressFormModal__address-in-map">
      <h1 className="AddressFormModal__title">
        {!isAnonymous ? (
          <Clickable
            className="AddressFormModal__icon AddressFormModal__go-back"
            onClick={addressInMapGoBackHandler}
          >
            <ChevronLeftSVG />
          </Clickable>
        ) : null}
        Encuentra tu dirección
        <Clickable
          className="AddressFormModal__icon AddressFormModal__close"
          onClick={() => {
            onClose();
            clearModal();
          }}
        >
          <CloseSVG />
        </Clickable>
      </h1>
      <ChooseAddressInMap
        onSelectedAddress={addressInMapHandler}
        initialCoordinates={coordinates}
        onSelectedCoordinates={selectedCoordinatesHandler}
      />
    </div>
  );

  return (
    <Modal
      {...props}
      closeOnClickOutside={false}
      opened={opened}
      onClose={() => {
        onClose();
        clearModal();
      }}
    >
      <Styles className="AddressFormModal" step={step}>
        {mapAddressPreviewNode}
        {addressFormNode}
        {chooseAddressInMapNode}
      </Styles>
    </Modal>
  );
};

AddressFormModal.defaultProps = {};

export default AddressFormModal;
