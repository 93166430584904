import { AxiosRequestHeaders } from "axios";

import { auth } from "config/artisn.config";
import CONSTANTS from "config/constants";

const { GENERAL, ARTISN } = CONSTANTS;
const { PLATFORM } = GENERAL;
const { ACCOUNT_ID } = ARTISN;

export const buildHeaders = async (
  additionalHeaders?: Record<string, string | number | boolean | undefined>
): Promise<AxiosRequestHeaders> => {
  const token = await auth()?.currentUser?.getIdToken();

  return {
    Platform: PLATFORM,
    account: `${ACCOUNT_ID}`,
    Authorization: `Bearer ${token}`,
    ...additionalHeaders
  };
};
