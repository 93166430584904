import React, { createContext, useMemo, useState } from "react";
import { ContextDevTool } from "react-context-devtool";

import { I18NProviderProps as Props } from "./i18N.context.types";
import { I18NProviderValue } from "./i18N.context.types";

// @ts-ignore
export const I18NContext = createContext<I18NProviderValue>();

const I18NProvider: React.FC<Props> = props => {
  const [selectedLanguage, setSelectedLanguage] = useState("es");

  const value: I18NProviderValue = useMemo(() => {
    return {
      selectedLanguage,
      setSelectedLanguage
    };
  }, [selectedLanguage]);

  return (
    <I18NContext.Provider value={value}>
      <ContextDevTool context={I18NContext} id="i18N" displayName="I18N" />
      {props.children}
    </I18NContext.Provider>
  );
};

export default I18NProvider;
