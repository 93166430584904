import React, { useMemo } from "react";

import InfoCard from "../InfoCard/InfoCard";
import Styles from "./StorePreview.styles";
import { defaultFunction } from "utils/common.utils";
import { getStoreDistanceUnit } from "utils/store.utils";
import { StorePreviewProps as Props } from "./StorePreview.types";
import useCatalogues from "contexts/catalogues/catalogues.hooks";
import { nextOpeningHourHelper } from "./StorePreview.helpers";

import StoreSVG from "../../../../public/assets/images/store.svg";
import InfoSVG from "../../../../public/assets/images/info.svg";

const StorePreview: React.FC<Props> = props => {
  const { className, isSelected, store } = props;
  const { onInfoClick = defaultFunction } = props;
  const { onContentClick = defaultFunction } = props;
  const { storeName, isOpen, schedules, address, distance } = store ?? {};
  const { selectedCatalogueId } = useCatalogues();
  const closed = !isOpen ? "StorePreview__info__fill-closed" : "";
  const selected = isSelected ? "selected" : "";

  const nextOpeningHour = useMemo(
    () => nextOpeningHourHelper(schedules, +selectedCatalogueId),
    [selectedCatalogueId, schedules]
  );

  const renderStoreInfo = () => (
    <>
      <p className="StorePreview__address">{address.toLowerCase()}</p>
      <div className="StorePreview__info">
        <div className={`StorePreview__info__fill ${closed}`}>
          <div className="StorePreview__info__fill-point" />
          <p className="StorePreview__info__fill-text">
            {isOpen ? "Abierto" : "Cerrado"}
          </p>
        </div>
        <div className="StorePreview__info__distance">
          <p className="StorePreview__info__distance-status">
            {isOpen ? "Más cercano" : "Abre: " + nextOpeningHour}
          </p>
          {getStoreDistanceUnit(distance)}
        </div>
      </div>
    </>
  );

  return (
    <Styles className={`StorePreview ${className} ${selected}`}>
      <InfoCard
        value={storeName.toLowerCase()}
        title={renderStoreInfo()}
        Icon={StoreSVG}
        IconToRight={InfoSVG}
        onContentClick={onContentClick}
        onRightIconClick={onInfoClick}
      />
    </Styles>
  );
};

StorePreview.defaultProps = {
  className: ""
};

export default StorePreview;
