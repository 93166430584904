import styled from "styled-components";

import { CartPayButtonStyledProps as Props } from "./CartPayButton.types";

const CartPayButtonStyled = styled.div<Props>`
  position: ${props => (props.talkShop ? "fixed" : "static")};
  width: 100%;
  bottom: 0;
  z-index: 3;

  &&.CartPayButton {
    .Cart__summary__button {
      width: 100%;
      padding: 1.6rem 0;
      border-radius: ${props => (props.talkShop ? "0" : "0.4rem")};
    }
  }

  .CartPayButton {
  }
`;

export default CartPayButtonStyled;
