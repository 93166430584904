// Company Graphql queries and mutations

export const fetchCompaniesQuery = `
query Companies {
    companiesList (orderBy: createdAt_DESC) {
      items {
        name
        email
        country
        city
        id
        createdAt
      updatedAt
      status
      opened
      }
    }
  }
  `;

export const fetchCurrentCompanyQuery = `
  query CurrentCompany($companyId: ID) {
    company(id: $companyId) {
      id
      name
      email
      country
      opened
      city
      userCompany {
        firstName
        lastName
        email
        phone
      }
    }
  }
  `;

export const fetchCompaniesByUserQuery = `
query  {
  fetchCompaniesByUser {
    items {
      city
      country
      email
      id
      name
    }
  }
} `;

export const createCompanyFormQuery = `
mutation CreateCompanyForm($data: CreateCompanyFormPayload!) {
  createCompanyForm(data: $data) {
    result
  }
}
`;

export const updateCompanyStatusQuery = `
mutation updateCompany($filter: CompanyKeyFilter) {
  companyUpdate(data: {opened: false}, filter:$filter){
    opened
  }
}
`;

export const updateLabelQuery = `
mutation updateLabel($data:FormTranUpdateInput!, $filter:FormTranKeyFilter) {
  formTranUpdate(data: $data, filter:$filter ) {
    label
  }
}
`;

export const updateCompanyFormQuery = `
mutation MyMutation($data: CompanyFormUpdateInput!, $filter: CompanyFormKeyFilter) {
  companyFormUpdate(data: $data, filter: $filter) {
    id
    json
    status
    updatedAt
    createdAt
  }
}
`;

export const fetchCompanyFormsQuery = `
query FetchCompanyForms($companyId: ID!, $formId: ID!) {
  fetchCompanyForm(companyId: $companyId, formId: $formId) {
    items {
      status
      json
      id
      createdAt
      updatedAt
    }
  }
}
`;

export const fetchCompanyFormsByFormCodeQuery = `
query FetchCompanyFormByFormCode($companyId: ID!, $formCode: String!) {
  fetchCompanyFormByFormCode(companyId: $companyId, formCode: $formCode) {
    items {
      createdAt
      id
      json
      status
      updatedAt
    }
  }
}
`;

export const fetchCompanyFormsbyCompany = `
query fetchCompanyFormsCompany ($companyId: ID!){
  company(id:$companyId) {
    companyForms {
      items {
        id
        json
        status
        updatedAt
        createdAt
      }
    }
  }
}
`;

export const fetchUsersAndCompanies = `
query{
  usersList {
    items {
      firstName
      lastName
      company {
        count
        items {
          id
          name
        }
      }
    }
  }
}
`;

export const fetchAttributeLabel = `
query{
  formTransList {
    items {
      id
      label
      masterDataFieldName {
        name
      }
    }
  }
}
`;
