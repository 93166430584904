// Billing info service hooks
import { useMutation, useQuery, useQueryClient } from "react-query";
import { BaseBillingData, BillingData, Country, Vendor } from "artisn/types";
import { Storage } from "@capacitor/storage";

import { postBillingData, deleteBillingData } from "./billingData.service";
import { fetchInvoiceSettings } from "./billingData.service";
import { fetchBillingDataList, putBillingData } from "./billingData.service";
import useAuth from "contexts/auth/auth.context.hooks";
import useBillingData from "contexts/billingData/billingData.context.hooks";
import CONSTANTS from "config/constants";
import useCountries from "contexts/countries/countries.hooks";
import useVendors from "contexts/vendors/vendors.hooks";

const { BILLING_DATA_TOKEN } = CONSTANTS.STORAGE;

/**
 * Hook to get user's billing information.
 *
 * @returns {UseQueryResult<BillingData[]>} Returns a use query result with the billing data
 */
export const useFetchBillingData = (countryId?: Country["id"]) => {
  const { uid, isAnonymous } = useAuth();
  const { id } = useCountries().selectedCountry;
  const selectedCountryId = countryId ?? id;

  return useQuery<BillingData[], Error>(
    [uid, "billingData", selectedCountryId],
    () => fetchBillingDataList(selectedCountryId),
    {
      enabled: !isAnonymous && !!uid,
      staleTime: 30 * 1000 * 60
    }
  );
};

/**
 * Hook to add billing information to user.
 *
 * @returns {UseMutationResult<BillingData, Error, BillingData, BillingData[]>} Returns a use mutation result to add billing
 */
export const usePostBillingData = (countryId?: Country["id"]) => {
  const queryClient = useQueryClient();
  const { uid } = useAuth();
  const { id } = useCountries().selectedCountry;
  const selectedCountryId = countryId ?? id;
  const { setSelectedBillingData } = useBillingData();

  return useMutation<BillingData | undefined, Error, BaseBillingData>(
    postBillingData,
    {
      // When mutate is called:
      onMutate: async () => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([
          uid,
          "billingData",
          selectedCountryId
        ]);
      },
      onSuccess: setSelectedBillingData,
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (error, newBillingData, previousBillingData) => {
        if (previousBillingData) {
          queryClient.setQueryData(
            [uid, "billingData", selectedCountryId],
            previousBillingData
          );
        }
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([uid, "billingData", selectedCountryId]);
      }
    }
  );
};

/**
 * Hook to delete billing information to user.
 *
 * @returns {UseMutationResult<void, Error, number, BillingData[]>} Returns a use mutation result to delete billing
 */
export const useDeleteBillingData = (countryId?: Country["id"]) => {
  const queryClient = useQueryClient();
  const { uid } = useAuth();
  const { id } = useCountries().selectedCountry;
  const selectedCountryId = countryId ?? id;
  const { selectedBillingData, setSelectedBillingData } = useBillingData();

  return useMutation<void, Error, number, BillingData[] | undefined>(
    config => deleteBillingData(config),
    {
      onMutate: async billingDataId => {
        await queryClient.cancelQueries([
          uid,
          "billingData",
          selectedCountryId
        ]);

        const previousBillingData = queryClient.getQueryData<BillingData[]>([
          uid,
          "billingData"
        ]);

        if (previousBillingData) {
          queryClient.setQueryData<BillingData[]>(
            [uid, "billingData", selectedCountryId],
            () => {
              const filterBillingData = previousBillingData.filter(
                billingData => billingData.id !== billingDataId
              );

              return filterBillingData;
            }
          );
        }

        return previousBillingData;
      },
      onSuccess: (data, billingDataId) => {
        if (selectedBillingData?.id === billingDataId) {
          setSelectedBillingData(undefined);
          Storage.remove({ key: BILLING_DATA_TOKEN });
        }
      },
      onError: (error, billingDataId, previousBillingData) => {
        if (previousBillingData) {
          queryClient.setQueryData(
            [uid, "billingData", selectedCountryId],
            previousBillingData
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries([uid, "billingData", selectedCountryId]);
      }
    }
  );
};

/**
 * Hook to update billing information to user.
 *
 * @returns {UseMutationResult<BillingData, Error, BillingData, BillingData[]>} Returns a use mutation result to update billing
 */
export const usePutBillingData = (countryId?: Country["id"]) => {
  const queryClient = useQueryClient();
  const { uid } = useAuth();
  const { setSelectedBillingData } = useBillingData();
  const { id } = useCountries().selectedCountry;
  const selectedCountryId = countryId ?? id;

  return useMutation<
    BillingData | undefined,
    Error,
    BillingData,
    BillingData[] | undefined
  >(putBillingData, {
    onMutate: async updatedBillingData => {
      await queryClient.cancelQueries([uid, "billingData", selectedCountryId]);

      const previousBillingData = queryClient.getQueryData<BillingData[]>([
        uid,
        "billingData"
      ]);

      // Optimistically update to the new value
      if (previousBillingData) {
        const newBillingData = [...previousBillingData];
        const id = newBillingData.findIndex(
          address => address.id === updatedBillingData.id
        );
        newBillingData[id] = updatedBillingData;
        queryClient.setQueryData<BillingData[]>(
          [uid, "billingData"],
          newBillingData
        );
      }

      return previousBillingData;
    },
    onSuccess: setSelectedBillingData,
    onError: (error, billingData, previousBillingData) => {
      if (previousBillingData) {
        queryClient.setQueryData(
          [uid, "billingData", selectedCountryId],
          previousBillingData
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([uid, "billingData", selectedCountryId]);
    }
  });
};

/**
 * Hook to fetch the invoice settings.
 *
 * @param {number} vendorId Vendor identifier
 * @returns
 */
export const useFetchInvoiceSettings = (vendorId?: Vendor["id"]) => {
  const { selectedVendorId } = useVendors();
  const chosenVendorId = vendorId ?? selectedVendorId;

  return useQuery(
    ["invoiceSettings", chosenVendorId],
    () => fetchInvoiceSettings(chosenVendorId),
    {
      staleTime: 60 * 1000 * 60
    }
  );
};
