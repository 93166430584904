import React, { createContext, useCallback, useMemo, useState } from "react";
import { Benefit, CartProduct, ShoppingCart, Wallet } from "artisn/types";
import { ContextDevTool } from "react-context-devtool";

import { ShoppingCartProviderProps as Props } from "./shoppingCart.context.types";
import { ShoppingCartProviderValue } from "./shoppingCart.context.types";

// @ts-ignore
export const ShoppingCartContext = createContext<ShoppingCartProviderValue>({});

const ShoppingCartProvider: React.FC<Props> = props => {
  const [shoppingCart, setShoppingCart] = useState<ShoppingCart>();
  const [wishlist, setWishlist] = useState<ShoppingCart>();
  const [benefitsWallet, setBenefitsWallet] = useState<Wallet>();
  const [temporalBenefit, setTemporalBenefit] = useState<Benefit>();
  const [isAnonymousCart, setIsAnonymousCart] = useState(false);

  const resetShoppingCartContext = () => {
    setShoppingCart(undefined);
    setWishlist(undefined);
    setBenefitsWallet(undefined);
    setTemporalBenefit(undefined);
  };

  const showModifiers = useCallback((product: CartProduct) => {
    const { questionsAndAnswers } = product;
    let modifiers = "";

    questionsAndAnswers.forEach(question => {
      const { answers } = question;
      answers.forEach(answer => {
        const { amount, name } = answer;
        if (amount) {
          modifiers += `${name} x ${amount}, `;
        }
      });
    });

    modifiers = modifiers.slice(0, -2);
    return modifiers;
  }, []);

  const value: ShoppingCartProviderValue = useMemo(() => {
    return {
      shoppingCart,
      setShoppingCart,
      wishlist,
      setWishlist,
      benefitsWallet,
      setBenefitsWallet,
      temporalBenefit,
      setTemporalBenefit,
      resetShoppingCartContext,
      showModifiers,
      isAnonymousCart,
      setIsAnonymousCart
    };
  }, [
    shoppingCart,
    wishlist,
    benefitsWallet,
    temporalBenefit,
    showModifiers,
    isAnonymousCart,
    setIsAnonymousCart
  ]);

  return (
    <ShoppingCartContext.Provider value={value}>
      <ContextDevTool
        context={ShoppingCartContext}
        id="shoppingCart"
        displayName="ShoppingCart"
      />
      {props.children}
    </ShoppingCartContext.Provider>
  );
};

export default ShoppingCartProvider;
