import { opacify } from "polished";
import { css } from "styled-components";

import variables from "styles/util/variables";
import { getMaxHeight } from "utils/styling.utils";
import CONSTANTS from "config/constants";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;
const primary = variables.palette["purple-s85-l45"];

const base = css`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%; //16px default to 10px
  }

  body {
    box-sizing: border-box;
    background-color: var(--palette-h0s0l95a1);
    max-width: 100%;
    margin-top: env(safe-area-inset-top);
    margin-bottom: env(safe-area-inset-bottom);
    margin-left: env(safe-area-inset-left);
    margin-right: env(safe-area-inset-right);
  }

  /* :is(.TabsMenu__page, .Layout) > * {
    grid-template-rows: max-content 1fr max-content;
  } */

  .SettingsDrawer {
    &__separator {
      height: 0.8rem;
      background-color: var(--palette-h0s0l95a1);
      margin-left: -2.4rem;
      margin-right: -2.4rem;
    }

    &__info-card {
      padding: 1.6rem 0;
    }

    &__drawer {
      &__header {
        position: relative;
        padding-bottom: 4rem;

        &__title {
          text-align: center;
          font-family: Poppins;
          font-size: 1.6rem;
          line-height: 2.4rem;
        }

        &__cross {
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
        }
      }

      &__buttonGroupText {
        font-family: Poppins;
        font-size: 1.2rem;
        line-height: 2rem;
        color: var(--palette-h0s0l40a1);
      }

      &__button {
        margin-top: auto;
        display: flex;
        justify-content: center;
        padding: 1rem 1.6rem;
        background: var(--palette-h0s0l10a1);
        border-radius: 0.4rem;
        border: none;
        cursor: pointer;

        &__text {
          font-family: Poppins;
          font-size: 1.6rem;
          line-height: 2.4rem;
          color: var(--palette-white);
        }
      }
    }

    &__buttonGroup {
      padding-top: 0.8rem;
      padding-bottom: 2.4rem;

      .Button--active {
        border: 0.1rem solid var(--palette-primary) !important;
        color: var(--palette-primary);
      }

      &__button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: 1.6rem 2.4rem;
        border: 0.1rem solid var(--palette-h0s0l80a1);
        width: 11.2rem;
        height: 4.8rem;

        font-size: 1.6rem;

        &:first-child {
          border-top-left-radius: 0.4rem;
          border-bottom-left-radius: 0.4rem;
        }

        &:last-child {
          border-top-right-radius: 0.4rem;
          border-bottom-right-radius: 0.4rem;
        }
      }
    }
  }

  .SearchBar__item--active {
    background-color: var(--palette-h0s0l95a1);
  }

  .CheckoutOrderStatus {
    &__modal {
      width: 100%;
      height: ${getMaxHeight};
    }
  }

  .Modal {
    border-radius: 1.6rem !important;
    &__body {
      padding-bottom: 1rem;
      border-radius: 1.6rem;
    }
  }

  .SearchBar__wrapper,
  .SearchBar__btn {
    border-radius: 0.8rem;
  }

  .SearchBar__wrapper > .SearchBar__btn.SearchBar__btn-search {
    padding-left: 1.2rem;
    width: 3.6rem;
  }

  .Category,
  .Products {
    &__drawer {
      width: 100%;
      height: 100%;
      padding-top: 1.6rem;

      &__close {
        position: absolute;
        top: 2.4rem;
        right: 2rem;
        z-index: 2;

        cursor: pointer;
      }
    }

    &__results {
      position: absolute;
      width: 100%;
      bottom: 0;

      padding: 1.6rem 2.4rem;
      background-color: var(--palette-white);
      z-index: 2;

      &&& &__button {
        font-family: inherit;
        width: 100%;
        justify-content: center;
        padding: 1.8rem 2.4rem;
      }
    }
  }

  .field {
    &&& label {
      color: var(--palette-h0s0l50a1);
      padding-left: 1rem;
      padding-bottom: 0.4rem;
      font-size: 1.4rem;
      font-weight: 500;

      &.Checkbox__label {
        padding-bottom: 0;
      }
    }

    &&& input {
      padding: 1rem;
      font-size: 1.6rem;
      border-radius: 0.8rem;
      font-weight: 400;
      height: 4.8rem;
      outline: none;
      line-height: 3rem;
      border-color: var(--palette-h0s0l90a1);

      &:hover {
        z-index: 1;
        border-color: var(--palette-primary);
      }

      &::placeholder {
        color: var(--palette-h0s0l80a1);
      }

      &:focus {
        z-index: 1;
        border-color: #d9d9d9;
        box-shadow: none;
      }

      &.PhoneNumberInput__input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &&& figure {
      z-index: 1;
    }

    &&& .SelectCountry {
      background-color: var(--palette-h0s0l95a1);

      &:hover {
        border-color: var(--palette-primary);

        & > * {
          border-color: var(--palette-primary);
        }
      }

      &__flag {
        border-color: var(--palette-h0s0l95a1);
      }

      &__select {
        border-color: var(--palette-h0s0l95a1);
      }
    }

    &&& .DocumentTypeSelect {
      &__selectContainer {
        background-color: transparent;
        border-color: var(--palette-h0s0l90a1);

        &:hover {
          border-color: var(--palette-primary);
        }

        &:focus-within {
          border-color: var(--palette-primary);
          box-shadow: 0rem 0rem 0rem 0.1rem ${opacify(-0.8, primary)};
        }
      }

      &__select {
        height: 4.8rem;
      }
    }
  }

  .fieldPassword {
    &&& label {
      position: relative;
      width: 20rem;
      height: 2rem;
      left: 1rem;

      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.8rem;
      margin-top: 1rem;
    }

    &&& input {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1rem;

      position: relative;
      font-weight: 500;
      font-size: 1.4rem;
      height: 4.4rem;

      color: var(--palette-dark);
      background-color: var(--palette-white);
      border-radius: 0.8rem !important;
    }
  }

  .border__item {
    border: 0.1rem solid var(--palette-black) !important;

    display: grid !important;
    grid-template-columns: max-content 1fr max-content;

    align-items: center !important;
    padding: 1.2rem 1.6rem !important;
    min-height: 6.4rem !important;
    border-radius: 0.4rem !important;

    &--inactive {
      border: 0.1rem solid var(--palette-h0s0l95a1) !important;
    }
  }

  .loading-shine {
    background-color: #f0f0f0;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      display: block;

      background: linear-gradient(
        to right,
        transparent,
        rgba(180, 180, 180, 0.3),
        transparent
      );

      animation: load 1.5s cubic-bezier(0.4, 0, 0.3, 1) infinite;

      position: absolute;
      left: -100%;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }

  div.SettingsDrawer__drawer {
    padding: 1.6rem 2.4rem;
    padding-top: calc(1.6rem + env(safe-area-inset-top));

    min-width: 40rem;
    min-height: var(--sizes-page-maxHeight);
    max-height: calc(var(--sizes-page-maxHeight) - 5.7rem);

    @media (hover: none) {
      width: var(--sizes-page-maxWidth);
      min-height: calc(var(--sizes-page-maxHeight) - 5.7rem);
    }
  }

  .SettingsDrawer {
    &__buttonGroup {
      &__button {
        flex: 1;
      }
    }
  }

  .NotificationsContainer {
    & &__container {
      @media (max-width: ${tablet}px) {
        bottom: 7.2rem;
      }

      @media (max-width: ${mobile}px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        right: 0.6rem;
        left: 0.6rem;
      }
    }
  }

  .UpdateAppModal,
  .OutOfServiceModal {
    &__backdrop {
      height: 100vh !important;
    }
  }

  .modal {
    grid-area: form;
    padding: 2rem;
    border-radius: 1.5rem !important;
    width: 50rem;

    @media only screen and (max-width: ${tablet}px) {
      position: absolute;
      top: 78% !important;
      width: 80rem;
      height: 52rem;
    }

    @media only screen and (max-width: ${mobile}px) {
      position: absolute;
      top: 80% !important;
      height: 48rem;
    }

    &__text {
      display: flex;
      align-items: center;
      font-size: 2rem;
      font-weight: 500;

      margin-top: 3rem;
      margin-bottom: 1.5rem;

      @media only screen and (max-width: ${mobile}px) {
        line-height: 3rem;

        margin: 1.6rem 0;
      }
    }

    &__passwordModal {
      position: static;
      margin-top: 3rem !important;
      display: flex;
      justify-content: flex-end;
    }

    &__passwordText {
      padding-top: 1rem;
      font-size: 1.6rem;
      font-weight: 600;
      margin-right: 1rem;
    }

    &__button {
      padding: 1.2rem 2rem 1.2rem 2rem !important;
      border-radius: 0.8rem !important;
      font-size: 1.6rem;
      font-weight: 600;

      color: var(--palette-white);
      background-color: var(--palette-black) !important;
      border: none;
    }
  }

  .ant-select-selection-placeholder {
    color: var(--palette-h0s0l50a1);
    font-size: 2rem;
  }

  .ant-select-selection-item {
    font-size: 2rem;
  }

  .ant-select-selection-search-input,
  .ant-select-selector {
    height: 4.8rem !important;
    align-items: center;
  }

  .ant-input,
  .ant-select-selector {
    background: transparent !important;
    box-shadow: unset !important;
  }

  .ant-input {
    height: 4.8rem;
    border-radius: 0.8rem;
    border-color: var(--palette-h0s0l90a1);

    &:hover {
      border-color: var(--palette-h0s0l90a1);
    }
  }

  .ant-select-item-option-selected {
    background-color: var(--palette-h193s46l88a1) !important;
  }

  .ant-message-notice-content {
    border-radius: 0.8rem;
  }

  .ant-message-notice-content {
    padding: 0 !important;
  }
  .ant-select-dropdown {
    .ant-select-item-option-content {
      display: flex;

      .BusinessLocation__img {
        width: 3rem !important;
        margin: 0 2rem;
      }
    }
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #262329;
    background-color: transparent;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #262329;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--palette-black);
    border-color: var(--palette-black);
  }

  .ant-form-item .ant-form-item-label > label {
    @media (max-width: ${tablet}px) {
      font-size: 1.2rem;
    }
  }

  .hide-desktop {
    @media (min-width: ${tablet}px) {
      display: none !important;
    }
  }

  .hide-mobile {
    @media (max-width: ${tablet}px) {
      display: none !important;
    }
  }

  .Modal {
    &__body {
      padding: 0;
    }
  }

  .ant-drawer-content-wrapper {
    .AdminFormHeader__cross {
      cursor: pointer;
    }
  }

  .ant-pagination-item {
    background-color: transparent;
    border: none;
    &-active {
      background-color: transparent;
      border-radius: 0.2rem;
      border: 0.1rem solid #1890ff;
    }
  }

  .ant-pagination-next {
    .ant-pagination-item-link {
      background-color: transparent;
      border-radius: 0.2rem;
      border: 0.1rem solid #1890ff;
    }
  }

  .ant-table-expanded-row-level-1 {
    z-index: 0 !important;
  }
`;

export default base;
